import { createSlice } from '@reduxjs/toolkit';

import addControlOptionReducer from './reducer/addControlOptionReducer';
import addDesignOptionReducer from './reducer/addDesignOptionReducer';
import addDesignTemplatesReducer from './reducer/addDesignTemplatesReducer';
import applyStateReducer from './reducer/applyStateReducer';
import deleteDesignOptionReducer from './reducer/deleteDesignOptionReducer';
import enableGroupLinkingReducer from './reducer/enableGroupLinkingReducer';
import linkAllDesignOptionsVariantsReducer from './reducer/linkAllDesignOptionsVariants';
import linkAllVariantsReducer from './reducer/linkAllVariantsReducer';
import removeControlOptionReducer from './reducer/removeControlOptionReducer';
import resetControlWithOptionReducer from './reducer/resetControlWithOptionReducer';
import selectSingleOptionReducer from './reducer/selectSingleOptionReducer';
import setAsVariantFlowReducer from './reducer/setAsVariantFlowReducer';
import setDesignOptionsReducer from './reducer/setDesignOptionsReducer';
import setECommerceProductIdReducer from './reducer/setECommerceProductIdReducer';
import setECommerceProductReducer from './reducer/setECommerceProductReducer';
import setGroupDesignDataReducer from './reducer/setGroupDesignDataReducer';
import setDesignOptionsEnabledReducer from './reducer/setIsDesignOptionsEnabled';
import setPageCountReducer, { UNSELECTED_PAGE_COUNT } from './reducer/setPageCountReducer';
import setProductDataReducer from './reducer/setProductDataReducer';
import setProductsDataReducer from './reducer/setProductsDataReducer';
import setSelectedDesignOptionReducer from './reducer/setSelectedDesignOptionReducer';
import setSelectedGroupReducer from './reducer/setSelectedGroupReducer';
import setSelectedProductIdReducer from './reducer/setSelectedProductIdReducer';
import setSelectedProductUidReducer from './reducer/setSelectedProductUidReducer';
import setSingleVariantProductDataReducer from './reducer/setSingleVariantProductDataReducer';
import setSpreadGroupsPerProductUidReducer from './reducer/setSpreadGroupsPerProductUidReducer';
import copySpreadReducer from './reducer/spreadManipulation/copySpreadReducer';
import removeSpreadReducer from './reducer/spreadManipulation/removeSpreadReducer';
import toggleExternalOptionReducer from './reducer/toggleExternalOptionReducer';
import toggleGroupLinkReducer from './reducer/toggleGroupLinkReducer';
import toggleMultiOptionReducer from './reducer/toggleMultiOptionReducer';
import updateControlOptionReducer from './reducer/updateControlOptionReducer';
import updateDesignOptionControlTitleReducer from './reducer/updateDesignOptionControlTitleReducer';
import updateDesignOptionReducer from './reducer/updateDesignOptionReducer';
import updateDigitizedElementsReducer from './reducer/updateDigitizedElementsReducer';
import updateMultipleProductItemReducer from './reducer/updateMultipleProductItemReducer';
import updateRecoloredElementsReducer from './reducer/updateRecoloredElementsReducer';
import updateVariationGroupsReducer from './reducer/updateVariationGroupsReducer';
import { VariantsState } from './types';

export const variantsInitialState: VariantsState = {
  product: {
    title: '',
    categoryTitle: '',
    description: '',
    productVariations: [],
    productControls: [],
    externalProductControls: [],
    controlsUIOrder: [],
    previewUrls: [],
    outOfStock: {},
    groupBy: [],
    unavailableProducts: {},
    pages: undefined,
  },

  replaceControlKeys: [],
  configuration: {},
  isLoaded: false,
  isVariantFlow: false,

  variationGroups: [],
  selectedGroupKey: undefined,
  selectedPageCount: UNSELECTED_PAGE_COUNT,
  selectedMultiOptions: {},
  selectedSingleOptions: {},
  selectedExternalOptions: {},

  designTemplates: {},
  spreadImages: {},
  layoutPerProductUids: {},

  existingVariants: [],
  eCommerceProduct: undefined,
  groupedSpreadsPerProductUids: {},
  designOptionsEnabled: false,
  products: undefined,
  selectedProductId: undefined,
  selectedProductUid: undefined,
};

const slice = createSlice({
  name: 'variants',
  initialState: variantsInitialState,
  reducers: {
    setProductDataAction: setProductDataReducer,
    setSingleVariantProductDataAction: setSingleVariantProductDataReducer,
    setSelectedGroupAction: setSelectedGroupReducer,
    setPageCountAction: setPageCountReducer,
    selectSingleOptionAction: selectSingleOptionReducer,
    toggleMultiOptionAction: toggleMultiOptionReducer,
    toggleGroupLinkAction: toggleGroupLinkReducer,
    linkAllVariantsAction: linkAllVariantsReducer,
    enableGroupLinkingAction: enableGroupLinkingReducer,
    setAsVariantFlowAction: setAsVariantFlowReducer,
    addDesignTemplatesAction: addDesignTemplatesReducer,
    setGroupDesignDataAction: setGroupDesignDataReducer,
    applyStateAction: applyStateReducer,
    toggleExternalOptionAction: toggleExternalOptionReducer,

    addControlOptionAction: addControlOptionReducer,
    updateControlOptionAction: updateControlOptionReducer,
    removeControlOptionAction: removeControlOptionReducer,
    resetControlWithOptionAction: resetControlWithOptionReducer,
    setSpreadGroupsPerProductUidAction: setSpreadGroupsPerProductUidReducer,
    setDesignOptionsAction: setDesignOptionsReducer,
    updateDesignOptionControlTitleAction: updateDesignOptionControlTitleReducer,
    addDesignOptionAction: addDesignOptionReducer,
    setDesignOptionsEnabledAction: setDesignOptionsEnabledReducer,
    updateVariationGroupsAction: updateVariationGroupsReducer,
    setSelectedDesignOptionAction: setSelectedDesignOptionReducer,
    updateDigitizedElementsAction: updateDigitizedElementsReducer,
    updateRecoloredElementsAction: updateRecoloredElementsReducer,
    deleteDesignOptionAction: deleteDesignOptionReducer,
    linkAllDesignOptionsVariantsAction: linkAllDesignOptionsVariantsReducer,
    updateDesignOptionAction: updateDesignOptionReducer,
    setECommerceProductAction: setECommerceProductReducer,
    setECommerceProductIdAction: setECommerceProductIdReducer,

    copySpreadAction: copySpreadReducer,
    removeSpreadAction: removeSpreadReducer,
    setProductsDataAction: setProductsDataReducer,
    setSelectedProductIdAction: setSelectedProductIdReducer,
    setSelectedProductUidAction: setSelectedProductUidReducer,
    updateMultipleProductItemAction: updateMultipleProductItemReducer,
  },
});

const { actions, reducer } = slice;

export const {
  setProductDataAction,
  setSingleVariantProductDataAction,
  setSelectedGroupAction,
  setPageCountAction,
  selectSingleOptionAction,
  toggleMultiOptionAction,
  toggleGroupLinkAction,
  linkAllVariantsAction,
  enableGroupLinkingAction,
  setAsVariantFlowAction,
  addDesignTemplatesAction,
  setGroupDesignDataAction,
  applyStateAction,
  toggleExternalOptionAction,

  addControlOptionAction,
  updateControlOptionAction,
  removeControlOptionAction,
  resetControlWithOptionAction,
  setSpreadGroupsPerProductUidAction,
  setDesignOptionsAction,
  updateDesignOptionControlTitleAction,
  addDesignOptionAction,
  setDesignOptionsEnabledAction,
  updateVariationGroupsAction,
  setSelectedDesignOptionAction,
  updateDigitizedElementsAction,
  updateRecoloredElementsAction,
  deleteDesignOptionAction,
  linkAllDesignOptionsVariantsAction,
  updateDesignOptionAction,
  setECommerceProductAction,
  setECommerceProductIdAction,

  copySpreadAction,
  removeSpreadAction,
  setProductsDataAction,
  setSelectedProductIdAction,
  setSelectedProductUidAction,
  updateMultipleProductItemAction,
} = actions;

export default reducer;
