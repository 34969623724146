import { FabricObject, util as fabricNativeUtils, Control as FabricControl } from 'fabric';

class CustomControl extends FabricControl {
  orientation: 'vertical' | 'horizontal';

  constructor(options: any) {
    super(options);
    this.orientation = options.orientation;
  }

  override render(
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    styleOverride: any,
    fabricObject: FabricObject,
  ) {
    const size = styleOverride.cornerSize || fabricObject.cornerSize || FabricObject.prototype.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabricNativeUtils.degreesToRadians(fabricObject.angle as number));
    ctx.fillStyle = styleOverride.cornerColor;

    if (this.orientation === 'vertical') {
      ctx.fillRect(-size / 2, -size, size, 2 * size);
      ctx.strokeRect(-size / 2, -size, size, 2 * size);
    } else {
      ctx.fillRect(-size, -size / 2, 2 * size, size);
      ctx.strokeRect(-size, -size / 2, 2 * size, size);
    }

    ctx.restore();
  }
}

export default CustomControl;
