import { util as fabricNativeUtils, Point as FabricPoint } from 'fabric';

import { Coords, MediaAddon, MediaImage } from 'editor/src/store/design/types';

import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';
import { POINT_0_0 } from 'editor/src/component/EditorArea/useCanvasRotation';

const imageElementToFabricProps = (
  elementData: MediaImage | MediaAddon,
  elementIndex: number,
  px2mm: (size: number) => number,
  mm2px: (size: number) => number,
  imageSize: { width: number; height: number },
  coordinatesStartPoint: Coords,
  isInteractable: boolean,
  canvasRotation: CanvasRotation,
) => {
  const rotatedImagePoint = fabricNativeUtils.rotatePoint(
    new FabricPoint(elementData.px, elementData.py),
    POINT_0_0,
    fabricNativeUtils.degreesToRadians(elementData.r),
  );

  const x = coordinatesStartPoint.left + mm2px(elementData.x + rotatedImagePoint.x);
  const y = coordinatesStartPoint.top + mm2px(elementData.y + rotatedImagePoint.y);
  const rotatedPoint = fabricNativeUtils.rotatePoint(
    new FabricPoint(x, y),
    canvasRotation.canvasCenter,
    canvasRotation.angleRad,
  );

  return {
    scaleX: elementData.pw / px2mm(imageSize.width),
    scaleY: elementData.ph / px2mm(imageSize.height),
    angle: elementData.r + elementData.pr + canvasRotation.angleDeg,
    left: rotatedPoint.x,
    top: rotatedPoint.y,
    zIndex: zIndex.MEDIA + elementIndex,
    evented: isInteractable,
    selectable: isInteractable,
    opacity: elementData.sample ? 0.3 : 1,
    flipX: elementData.flipX,
    flipY: elementData.flipY,
  };
};

export default imageElementToFabricProps;
