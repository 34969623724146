import { ActiveSelection, Canvas as FabricCanvas, Group as FabricGroup } from 'fabric';

import { Coords } from 'editor/src/store/design/types';

import CustomFabricObject from 'editor/src/fabric/CustomFabricObject';

import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';

export function isGroupSelected(fabricCanvas: FabricCanvas): boolean {
  const activeObject = fabricCanvas.getActiveObject();
  return activeObject instanceof FabricGroup && activeObject.getObjects().length > 1;
}

export function getActiveSelection(fabricCanvas: FabricCanvas): ActiveSelection | undefined {
  if (isGroupSelected(fabricCanvas)) {
    return fabricCanvas.getActiveObject() as ActiveSelection;
  }

  return undefined;
}

export function getActiveSelectionElementOffset(activeSelectionRect: ObjectRect | undefined): Coords {
  let left = 0;
  let top = 0;

  if (activeSelectionRect) {
    left -= activeSelectionRect.left + activeSelectionRect.width / 2;
    top -= activeSelectionRect.top + activeSelectionRect.height / 2;
  }

  return { left, top };
}

export function getActiveSelectionElementRect(originalRect: ObjectRect, offset: Coords): ObjectRect {
  return {
    left: originalRect.left + offset.left,
    top: originalRect.top + offset.top,
    width: originalRect.width,
    height: originalRect.height,
    angle: originalRect.angle,
  };
}

export function getActiveSelectionRect(activeSelection: ActiveSelection): ObjectRect {
  return {
    left: activeSelection.left,
    top: activeSelection.top,
    width: activeSelection.width,
    height: activeSelection.height,
    angle: activeSelection.angle,
  };
}

export function getSelectionUuids(elements: CustomFabricObject[]) {
  return elements
    .map((element) => {
      return !element.groupSelectionDisabled ? element.uuid : undefined;
    })
    .filter((uuid) => uuid !== undefined)
    .sort();
}
