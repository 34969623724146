import { Path as FabricPath } from 'fabric';

import type { Bleed, Coords } from 'editor/src/store/design/types';

import type { convFn } from 'editor/src/util/convFn';

function getTrimClipPath(bleeds: Bleed[] | undefined, mm2px: convFn, showBleeds: boolean, pageCoords: Coords) {
  const trim = bleeds?.find((b) => b.name === 'Trim');
  if (!trim) {
    return undefined;
  }

  let { x, y, path } = trim;
  if (!showBleeds) {
    x = trim.extra.inner_x;
    y = trim.extra.inner_y;
    path = trim.extra.inner_path;
  }

  if (!path) {
    return undefined;
  }

  return new FabricPath(path, {
    absolutePositioned: true,
    left: mm2px(x) + pageCoords.left - 1,
    top: mm2px(y) + pageCoords.top - 1,
    scaleX: mm2px(1),
    scaleY: mm2px(1),
  });
}

export default getTrimClipPath;
