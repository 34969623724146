import React, { useMemo } from 'react';

import { Coords, Page, SpotFinishingType } from 'editor/src/store/design/types';

import useFabricUtils from 'editor/src/util/useFabricUtils';

import { getVisibleAreaAsPolygons } from 'editor/src/component/EditorArea/Spread/Page/contentClippingUtils';
import FoilElements from 'editor/src/component/EditorArea/Spread/Page/FoilElements/FoilElements';
import getTrimClipPath from 'editor/src/component/EditorArea/Spread/Page/getTrimClipPath';
import WhiteUnderbaseElements from 'editor/src/component/EditorArea/Spread/Page/WhiteUnderbaseElements/WhiteUnderbaseElements';
import { NO_CANVAS_ROTATION } from 'editor/src/component/EditorArea/useCanvasRotation';

import MediaElements from './MediaElements';

interface Props {
  pages: Page[];
  pagesKey: string;
  spreadIndex: number;
  spreadCoords: Coords;
  spotFinishingType: SpotFinishingType | undefined;
  whiteUnderbase: boolean;
}

function PageMedia({ pages, pagesKey, spreadIndex, spreadCoords, spotFinishingType, whiteUnderbase }: Props) {
  const { mm2px } = useFabricUtils();
  const firstPage = pages[0];
  const { media, content, bleed, mediabox } = firstPage.groups;

  const pageCoords = useMemo(
    () => ({ left: spreadCoords.left + mm2px(firstPage.x || 0), top: spreadCoords.top + mm2px(firstPage.y || 0) }),
    [mm2px, spreadCoords, firstPage.x, firstPage.y],
  );

  const { fullArea } = useMemo(
    () => getVisibleAreaAsPolygons(pages, spreadCoords, mm2px, NO_CANVAS_ROTATION, undefined),
    [pagesKey, spreadCoords, mm2px, content, bleed, mediabox],
  );

  const trimClipPath = useMemo(() => getTrimClipPath(bleed, mm2px, false, pageCoords), [bleed, mm2px, pageCoords]);

  const areaWidth = mm2px(firstPage.width);

  return (
    <>
      {media?.map((element, index) => (
        <MediaElements
          key={element.uuid}
          spreadIndex={spreadIndex}
          pageIndex={0}
          elementIndex={index}
          pageCoords={pageCoords}
          element={element}
          contentClipPolygons={fullArea}
          contentClipPath={trimClipPath}
          areaWidth={areaWidth}
        />
      ))}
      {spotFinishingType && media && media.length > 0 && mediabox && mediabox.length > 0 && (
        <FoilElements
          pageCoords={pageCoords}
          contentClippingPolygons={fullArea}
          mediaBox={mediabox[0]}
          mediaElements={media}
          contentClipPath={trimClipPath}
          spotFinishingType={spotFinishingType}
        />
      )}
      {whiteUnderbase && media && media.length > 0 && mediabox && mediabox.length > 0 && (
        <WhiteUnderbaseElements
          pageCoords={pageCoords}
          contentClippingPolygons={fullArea}
          mediaBox={mediabox[0]}
          mediaElements={media}
          contentClipPath={trimClipPath}
        />
      )}
    </>
  );
}

export default React.memo(PageMedia);
