import { FabricObject, StaticCanvas, Canvas as FabricCanvas, Point as FabricPoint } from 'fabric';
import { useContext, useLayoutEffect } from 'react';

import { GroupContext } from './FabricGroupComponent';
import { disposeElement } from './fabricUtils';

function useObjectUpdate(fabricCanvas: FabricCanvas | StaticCanvas, element: FabricObject) {
  const groupContext = useContext(GroupContext);

  useLayoutEffect(() => {
    const containerContext = groupContext ?? fabricCanvas;
    if (groupContext) {
      // after adding element to the group it will be repositioned otherwise
      const strokeWidth = element.strokeWidth || 0;
      element.setXY(
        new FabricPoint(
          groupContext.left - element.left - strokeWidth / 2,
          groupContext.top - element.top - strokeWidth / 2,
        ),
        'center',
        'center',
      );
    }

    containerContext.add(element);

    return () => {
      disposeElement(element);
      containerContext.remove(element);
      fabricCanvas.requestRenderAll();
    };
  }, [element]);

  useLayoutEffect(() => {
    element.setCoords();
    fabricCanvas.requestRenderAll();
  });
}

export default useObjectUpdate;
