import { StaticCanvas, Canvas as FabricCanvas, FabricObject } from 'fabric';

function assertNumber(value: number | undefined): value is number {
  return value !== undefined && !Number.isNaN(value);
}

function orderObjects(canvas: FabricCanvas | StaticCanvas) {
  canvas._objects.sort((a: FabricObject & { zIndex?: number }, b: FabricObject & { zIndex?: number }) => {
    if (!assertNumber(a.zIndex)) {
      // eslint-disable-next-line no-console
      console.error('one fabric element does not have a zIndex', a);
      return -1;
    }

    if (!assertNumber(b.zIndex)) {
      // eslint-disable-next-line no-console
      console.error('one fabric element does not have a zIndex', b);
      return 1;
    }

    return a.zIndex - b.zIndex;
  });
}

export default orderObjects;
