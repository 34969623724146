import { Textbox as FabricTextbox } from 'fabric';

export interface IFabricText extends Partial<FabricTextbox> {
  zIndex?: number;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
class CustomFabricText extends FabricTextbox<IFabricText> {
  declare zIndex: number;
}

export default CustomFabricText;
