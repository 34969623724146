import { captureException } from '@sentry/react';
import { DrawContext, FabricObject, Group as FabricGroup, util as fabricUtils } from 'fabric';
import { GroupProps } from 'fabric/dist/src/shapes/Group';

export interface IGroupOptions extends Partial<GroupProps> {
  zIndex: number;
  customType?: string;
  absoluteClipPath?: boolean;
}

class CustomFabricGroup extends FabricGroup {
  declare zIndex: number;
  declare absoluteClipPath: boolean | undefined;
  customType = undefined;

  /**
   * Prepare clipPath state and cache and draw it on instance's cache
   * @param {CanvasRenderingContext2D} ctx
   * @param {FabricObject} clipPath
   */
  override _drawClipPath(ctx: CanvasRenderingContext2D, clipPath: FabricObject | undefined, context: DrawContext) {
    // fabric changed the way how the nesting clipPath behaves. Added workaround to be able to keep the previous solution
    if (!this.absoluteClipPath) {
      super._drawClipPath(ctx, clipPath, context);
      return;
    }

    this._drawAbsolutePositionedClipPath(ctx, clipPath, context);
  }

  /**
   * Prepare clipPath state and cache and draw it on instance's cache
   * @param {CanvasRenderingContext2D} ctx
   * @param {FabricObject} clipPath
   */
  _drawAbsolutePositionedClipPath(
    ctx: CanvasRenderingContext2D,
    clipPath: FabricObject | undefined,
    context: DrawContext,
  ) {
    if (!clipPath) {
      return;
    }

    let cacheCanvas = clipPath._cacheCanvas;
    if (!cacheCanvas) {
      clipPath._createCacheCanvas();
      cacheCanvas = clipPath._cacheCanvas;

      if (!cacheCanvas) {
        captureException('Cached canvas is missing for absolute clipPath inside group', {
          extra: { clipPath: clipPath.toJSON() },
        });
        super._drawClipPath(ctx, clipPath, context);
        return;
      }
    }

    // needed to setup a couple of variables
    // path canvas gets overridden with this one.
    clipPath._set('canvas', this.canvas);
    clipPath.shouldCache();
    clipPath._transformDone = true;
    (clipPath as any).renderCache({ forClipping: true });

    ctx.save();
    // DEBUG: uncomment this line, comment the following
    // ctx.globalAlpha = 0.4
    if (clipPath.inverted) {
      ctx.globalCompositeOperation = 'destination-out';
    } else {
      ctx.globalCompositeOperation = 'destination-in';
    }

    if (clipPath.absolutePositioned) {
      const m = fabricUtils.invertTransform(this.calcTransformMatrix());
      ctx.transform(m[0], m[1], m[2], m[3], m[4], m[5]);
    }
    clipPath.transform(ctx);
    const { zoomX = 1, zoomY = 1, cacheTranslationX = 0, cacheTranslationY = 0 } = clipPath;

    ctx.scale(1 / zoomX, 1 / zoomY);
    ctx.drawImage(cacheCanvas, -cacheTranslationX, -cacheTranslationY);
    ctx.restore();
  }
}

export default CustomFabricGroup;
