import { popularFontList, embroideryFontList } from '@gelatoas/design-editor-fonts';

import { FontDefinition, FontStatus } from 'editor/src/store/fonts/types';

import type { FontMetaData } from '@gelatoas/design-editor-fonts';

function fontMetadataToFontDefinition(metadata: FontMetaData): FontDefinition {
  const [isPopular, popularOrder] = checkFontInListAndGetIndex(popularFontList, metadata.fontFile);
  const [isEmbroidery, embroideryOrder] = checkFontInListAndGetIndex(embroideryFontList, metadata.fontFile);

  return {
    status: FontStatus.new,
    metadata,
    isPopular,
    isEmbroidery,
    popularOrder,
    embroideryOrder,
  };
}

function checkFontInListAndGetIndex(fontList: string[], fontFile: string): [boolean, number | undefined] {
  const index = fontList.indexOf(fontFile);
  return index === -1 ? [false, undefined] : [true, index + 1];
}

export default fontMetadataToFontDefinition;
