import { StaticCanvas } from 'fabric/fabric-impl';

function applyWhiteUnderbase(fabricCanvas: StaticCanvas) {
  const canvas = fabricCanvas.getElement();

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return;
  }

  ctx.save();
  ctx.resetTransform();
  ctx.globalCompositeOperation = 'source-in';

  const { width, height } = canvas;
  ctx.fillStyle = 'rgba(255, 255, 255, 0.25)';
  ctx.fillRect(0, 0, width, height);
  ctx.restore();
}

export default applyWhiteUnderbase;
