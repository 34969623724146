import { util as fabricNativeUtils, Point as FabricPoint } from 'fabric';

import getSpread from 'editor/src/store/design/selector/getSpread';
import { Page, Spread } from 'editor/src/store/design/types';
import getContentAreaFromSpread from 'editor/src/store/design/util/getContentAreaFromSpread';
import getFocusedContent from 'editor/src/store/editor/selector/getFocusedContent';
import { RootState } from 'editor/src/store/index';

import limitPrecision from 'editor/src/util/limitPrecision';

import isPagePrintable from '../util/isPagePrintable';

export type Area = { left: number; top: number; width: number; height: number };

function getNewElementDisplayByArea<T extends { width: number; height: number }>(
  area: Area,
  rotation: number,
  getDisplaySize: (area: Area) => T,
  x?: number,
  y?: number,
) {
  const { width, height, ...other } = getDisplaySize(area);

  const placeCenterX = x ?? area.left + area.width / 2;
  const placeCenterY = y ?? area.top + area.height / 2;

  const center = fabricNativeUtils.rotatePoint(
    new FabricPoint(placeCenterX - width / 2, placeCenterY - height / 2),
    new FabricPoint(placeCenterX, placeCenterY),
    fabricNativeUtils.degreesToRadians(rotation),
  );

  return {
    center,
    rotation,
    width: limitPrecision(width),
    height: limitPrecision(height),
    area,
    ...other,
  };
}

export function getNewElementDisplayForSpread<T extends { width: number; height: number }>(
  spread: Spread,
  getDisplaySize: (area: Area) => T,
  x?: number,
  y?: number,
) {
  const area = getContentAreaFromSpread(spread);
  return area ? getNewElementDisplayByArea(area, 0, getDisplaySize, x, y) : undefined;
}

function getNewElementDisplay<T extends { width: number; height: number }>(
  state: RootState,
  spreadIndex: number,
  getDisplaySize: (area: Area) => T,
  x?: number,
  y?: number,
) {
  const focusedContent = getFocusedContent(state);
  const spread = getSpread(state, spreadIndex);
  let area: Area | undefined;

  if (focusedContent) {
    area = {
      left: focusedContent.x,
      top: focusedContent.y,
      width: focusedContent.width,
      height: focusedContent.height,
    };
  } else {
    const printablePage = spread?.pages.find(isPagePrintable);
    area = getAreaFromPage(printablePage);
  }

  if (!area) {
    return undefined;
  }

  const rotation = focusedContent?.rotate || 0;
  return getNewElementDisplayByArea(area, rotation, getDisplaySize, x, y);
}

function getAreaFromPage(page: Page | undefined): Area | undefined {
  if (!page) {
    return undefined;
  }

  const mirroredContent = page.groups.content?.find((content) => content.extra?.mirroring === 'mirror');
  if (mirroredContent) {
    return {
      left: mirroredContent.x + page.x,
      top: mirroredContent.y + page.y,
      width: mirroredContent.width,
      height: mirroredContent.height,
    };
  }

  const mediabox = page.groups.mediabox?.[0];
  if (!mediabox) {
    return undefined;
  }

  return {
    left: mediabox.x + page.x,
    top: mediabox.y + page.y,
    width: mediabox.width,
    height: mediabox.height,
  };
}

export default getNewElementDisplay;
