import { FabricObject } from 'fabric';
import { RectProps } from 'fabric/src/shapes/Rect';
import React, { useImperativeHandle, useLayoutEffect, useState } from 'react';

import CustomFabricRect from 'editor/src/fabric/CustomFabricRect';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import setCursors from './setCursors';
import useControlVisibility, { ControlVisibility } from './useControlVisibility';
import useEvent, { EventHandler } from './useEvent';
import useEvents from './useEvents';
import useObjectProps from './useObjectProps';
import useObjectUpdate from './useObjectUpdate';

interface Props extends Partial<RectProps> {
  zIndex?: number;
  uuid?: number;
  events?: { [eventType: string]: EventHandler };
  controlVisibility?: ControlVisibility;
  onMouseDownBefore?: EventHandler;
  onMouseMove?: EventHandler;
  onMouseUp?: EventHandler;
  onMouseOver?: EventHandler;
  onMouseOut?: EventHandler;
  onModified?: EventHandler;
  onMouseDblClick?: EventHandler;
  onSelected?: EventHandler;
  onMove?: EventHandler;
}

function FabricRectComponent(props: Props, ref: React.Ref<CustomFabricRect>) {
  const fabricCanvas = useFabricCanvas();
  const [element] = useState(() => new CustomFabricRect());
  const {
    events,
    onMouseDownBefore,
    onMouseMove,
    onMouseUp,
    onMouseOver,
    onMouseOut,
    onMouseDblClick,
    onModified,
    controlVisibility,
    onSelected,
    onMove,
    ...fabricProps
  } = props;
  useObjectProps(element, fabricProps as Partial<FabricObject>);

  useImperativeHandle(ref, () => element, [element]);

  useEvents(element, events);
  useEvent(element, 'moving', onMove);
  useEvent(element, 'selected', onSelected);
  useEvent(element, 'mouseover', onMouseOver);
  useEvent(element, 'mouseout', onMouseOut);
  useEvent(element, 'mousedown:before', onMouseDownBefore);
  useEvent(element, 'mousemove', onMouseMove);
  useEvent(element, 'mouseup', onMouseUp);
  useEvent(element, 'modified', onModified);
  useEvent(element, 'mousedblclick', onMouseDblClick);

  useControlVisibility(element, controlVisibility);

  // to be done only once per element
  useLayoutEffect(() => {
    setCursors(element);
  }, [element]);

  useObjectUpdate(fabricCanvas, element);

  return null;
}

export default React.memo(React.forwardRef(FabricRectComponent));
