import { filters as fabricFilters } from 'fabric';
import { T2DPipelineState } from 'fabric/src/filters/typedefs';

class Saturation extends fabricFilters.BaseFilter<'Saturation'> {
  static override type = 'Saturation';

  declare saturation: number;

  static override defaults = {
    saturation: 0,
  };

  protected override getFragmentSource(): string {
    return (
      'precision highp float;\n' +
      'uniform sampler2D uTexture;\n' +
      'uniform float uSaturation;\n' +
      'varying vec2 vTexCoord;\n' +
      'void main() {\n' +
      'vec4 color = texture2D(uTexture, vTexCoord);\n' +
      'float rgMax = max(color.r, color.g);\n' +
      'float rgbMax = max(rgMax, color.b);\n' +
      'color.r += rgbMax != color.r ? (rgbMax - color.r) * (-uSaturation) : 0.00;\n' +
      'color.g += rgbMax != color.g ? (rgbMax - color.g) * (-uSaturation) : 0.00;\n' +
      'color.b += rgbMax != color.b ? (rgbMax - color.b) * (-uSaturation) : 0.00;\n' +
      'gl_FragColor = color;\n' +
      '}'
    );
  }

  override applyTo2d(options: T2DPipelineState) {
    if (this.saturation === 0) {
      return;
    }
    const { imageData } = options;
    const { data } = imageData;
    const len = data.length;
    const adjust = this.saturation;

    for (let i = 0; i < len; i += 4) {
      const max = Math.max(data[i], data[i + 1], data[i + 2]);
      data[i] += max !== data[i] ? (max - data[i]) * adjust : 0;
      data[i + 1] += max !== data[i + 1] ? (max - data[i + 1]) * adjust : 0;
      data[i + 2] += max !== data[i + 2] ? (max - data[i + 2]) * adjust : 0;
    }
  }

  override getUniformLocations(gl: WebGLRenderingContext, program: WebGLProgram) {
    return {
      uSaturation: gl.getUniformLocation(program, 'uSaturation'),
    };
  }

  override sendUniformData(gl: WebGLRenderingContext, uniformLocations: { [name: string]: WebGLUniformLocation }) {
    gl.uniform1f(uniformLocations.uSaturation, this.saturation / 100);
  }

  override isNeutralState() {
    return this.saturation === 0;
  }

  override toObject() {
    return {
      ...super.toObject(),
      saturation: this.saturation,
    };
  }
}

export default Saturation;
