import { PayloadAction } from '@reduxjs/toolkit';

import { HelpCenterArticlesConfig, HostSettingsState } from 'editor/src/store/hostSettings/types';

const setHelpCenterArticlesReducer = (state: HostSettingsState, action: PayloadAction<HelpCenterArticlesConfig>) => {
  if (state.enableHelpCenter?.articlesSearchString !== action.payload.articlesSearchString) {
    return;
  }
  if (!state.enableHelpCenter) {
    state.enableHelpCenter = {};
  }
  state.enableHelpCenter.articles = action.payload.articles;
};

export default setHelpCenterArticlesReducer;
