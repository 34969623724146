import { PluginName } from 'editor/src/store/plugins/types';

export type CustomTabInfo = {
  id: string;
  translationKey?: string;
  title: string;
} & ({ iconName: string } | { iconUrl: string } | { iconSVG: string });

type PluginTabBase = {
  title?: string;
} & ({ iconName: string } | { iconUrl: string } | { iconSVG: string });

export type PluginTabInfo = PluginTabBase & {
  type: 'plugin';
  id: TAB_NAMES;
  plugin: string;
  translationKey: string;
};

export type GenericPluginTabInfo = PluginTabBase & {
  type: 'generic-plugin';
  id: string;
  plugin: string;
};

export type TabInfo = TAB_NAMES | CustomTabInfo | PluginTabInfo | GenericPluginTabInfo;
export interface GroupedTabData {
  groupedItems: TabInfo[][];
  stickyItems: TabInfo[];
}

export interface SidebarState {
  isVisible: boolean;
  activeTabIndex: number;
  lastNonSystemTabIndex: number;
  baseTabs: Array<TAB_NAMES | CustomTabInfo>;
  requestedBaseTabs: Array<TAB_NAMES | CustomTabInfo>;
  dynamicTabs: { tab: TAB_NAMES; position: number | undefined }[];
  pluginTabs: Array<PluginTabInfo | GenericPluginTabInfo>;
  tabs: TabInfo[];
  lastGroupItems: Array<TAB_NAMES | string>;
  stickyTabs: Array<TAB_NAMES | string>;
  isShopifyOptionsInfoBannerDismissed: boolean;
  isTextPersonalizationPromotionBannerDismissed: boolean;
  isImagePersonalizationPromotionBannerDismissed: boolean;
  payload?: any;
}

export interface SidebarProps {
  index: number;
  payload?: any;
}

export type PluginTabs = {
  [key in PluginName]?: {
    tab: JSX.Element;
    tabContent?: JSX.Element;
  };
};

export enum TAB_NAMES {
  PRODUCT = 'PRODUCT',
  GALLERY = 'GALLERY',
  SETTINGS = 'SETTINGS',
  WARNINGS = 'WARNINGS',
  LAYERS = 'LAYERS',
  FOIL_LAYERS = 'FOIL_LAYERS',
  CROP = 'CROP',
  PROPERTIES = 'PROPERTIES',
  FONT_FAMILIES = 'FONT_FAMILIES',
  FONT_COLORS = 'FONT_COLORS',
  LAYOUTS = 'LAYOUTS',
  ADDONS = 'ADDONS',
  TEXTS = 'TEXTS',
  FORMATS = 'FORMATS',
  GRID_DESIGNS = 'GRID_DESIGNS',
  FILTERS = 'FILTERS',
  IMAGE_MANIPULATION = 'IMAGE_MANIPULATION',
  ADJUSTMENTS = 'ADJUSTMENTS',
  COLLECTIONS = 'COLLECTIONS',
  COLOR_PICKER = 'COLOR_PICKER',
  PERSONALIZATION_FLOW = 'PERSONALIZATION_FLOW',
  PERSONALIZATION = 'PERSONALIZATION',
  PERSONALIZE_LAYERS = 'PERSONALIZE_LAYERS',
  SHUTTERSTOCK = 'SHUTTERSTOCK',
  PLUGIN = 'PLUGIN',
  IMAGE_COLOR = 'IMAGE_COLOR',
  THREAD_COLOR = 'THREAD_COLOR',
  VARIANTS = 'VARIANTS',
  EDIT_IMAGE = 'EDIT_IMAGE',
  PATTERN = 'PATTERN',
  SHAPES = 'SHAPES',
  EFFECTS = 'EFFECTS',
  IMAGE_SHADOW = 'IMAGE_SHADOW',
  TEXT_OUTLINE_COLOR_PICKER = 'TEXT_OUTLINE_COLOR_PICKER',
  SHADOW_COLOR_PICKER = 'SHADOW_COLOR_PICKER',
  LINE_STROKE_COLOR = 'LINE_STROKE_COLOR',
  RECTANGLE_STROKE_COLOR = 'RECTANGLE_STROKE_COLOR',
  RECTANGLE_FILL_COLOR = 'RECTANGLE_FILL_COLOR',
  SCENE = 'SCENE',
  PLACEHOLDER = 'PLACEHOLDER',
  MOCKUP = 'MOCKUP',
  PERSONALIZE = 'PERSONALIZE',
  CALENDAR_EVENTS = 'CALENDAR_EVENTS',
  DESIGN_TEMPLATES = 'DESIGN_TEMPLATES',

  // mobile
  FONT_SIZE = 'FONT_SIZE',
  REPLACE_IMAGE = 'REPLACE_IMAGE',
  POSITION = 'POSITION',
  PRODUCT_FITTING = 'PRODUCT_FITTING',
  TEXT_ALIGN = 'TEXT_ALIGN',
  TEXT_SPACING = 'TEXT_SPACING',
  TEXT_FITTING = 'TEXT_FITTING',
  CROP_ZOOM = 'CROP_ZOOM',
  LINE_STYLES = 'LINE_STYLES',
  RECTANGLE_STYLES = 'RECTANGLE_STYLES',
  LINE_EDGE_1 = 'LINE_EDGE_1',
  LINE_EDGE_2 = 'LINE_EDGE_2',
  HELP_CENTER = 'HELP_CENTER',
}

export const COLOR_RELATED_TAB_NAMES = [
  TAB_NAMES.FONT_COLORS,
  TAB_NAMES.TEXT_OUTLINE_COLOR_PICKER,
  TAB_NAMES.SHADOW_COLOR_PICKER,
  TAB_NAMES.LINE_STROKE_COLOR,
  TAB_NAMES.RECTANGLE_STROKE_COLOR,
  TAB_NAMES.RECTANGLE_FILL_COLOR,
  TAB_NAMES.IMAGE_COLOR,
  TAB_NAMES.THREAD_COLOR,
];

export const SystemTabNames = [
  TAB_NAMES.CROP,
  TAB_NAMES.PROPERTIES,
  TAB_NAMES.FONT_COLORS,
  TAB_NAMES.FONT_FAMILIES,
  TAB_NAMES.FILTERS,
  TAB_NAMES.ADJUSTMENTS,
  TAB_NAMES.IMAGE_MANIPULATION,
  TAB_NAMES.COLOR_PICKER,
  TAB_NAMES.PERSONALIZATION_FLOW,
  TAB_NAMES.PERSONALIZATION,
  TAB_NAMES.FONT_SIZE,
  TAB_NAMES.REPLACE_IMAGE,
  TAB_NAMES.POSITION,
  TAB_NAMES.TEXT_ALIGN,
  TAB_NAMES.TEXT_SPACING,
  TAB_NAMES.CROP_ZOOM,
  TAB_NAMES.IMAGE_COLOR,
  TAB_NAMES.THREAD_COLOR,
  TAB_NAMES.GRID_DESIGNS,
  TAB_NAMES.EDIT_IMAGE,
  TAB_NAMES.PATTERN,
  TAB_NAMES.EFFECTS,
  TAB_NAMES.IMAGE_SHADOW,
  TAB_NAMES.TEXT_OUTLINE_COLOR_PICKER,
  TAB_NAMES.SHADOW_COLOR_PICKER,
  TAB_NAMES.LINE_STROKE_COLOR,
  TAB_NAMES.RECTANGLE_STROKE_COLOR,
  TAB_NAMES.RECTANGLE_FILL_COLOR,
  TAB_NAMES.LINE_STYLES,
  TAB_NAMES.RECTANGLE_STYLES,
  TAB_NAMES.LINE_EDGE_1,
  TAB_NAMES.LINE_EDGE_2,
];

export function isNotDefaultTab(tab: TabInfo): tab is CustomTabInfo {
  return typeof tab !== 'string';
}

export function isCustomTab(tab: TabInfo): tab is CustomTabInfo {
  return typeof tab !== 'string' && (tab as PluginTabInfo).type !== 'plugin';
}

export function isPluginTab(tab: TabInfo): tab is PluginTabInfo {
  return (
    typeof tab !== 'string' &&
    ((tab as PluginTabInfo).type === 'plugin' || (tab as GenericPluginTabInfo).type === 'generic-plugin')
  );
}

export function isSystemTab(tab: TabInfo): boolean {
  return SystemTabNames.includes(tab as TAB_NAMES);
}

export function isGenericPluginTabInfo(tab: TabInfo): tab is GenericPluginTabInfo {
  return typeof tab !== 'string' && (tab as GenericPluginTabInfo).type === 'generic-plugin';
}
