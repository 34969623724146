import { Gradient as FabricGradient } from 'fabric';

export const SHADOW_OFFSET_Y = 4;
export const SHADOW_HEIGHT = 4;

const gradientColorStops = [
  {
    offset: 0,
    color: 'rgba(0, 0, 0, 0)',
  },
  {
    offset: 0.4,
    color: 'rgba(0, 0, 0, 0.1)',
  },
  {
    offset: 0.7,
    color: 'rgba(0, 0, 0, 0.2)',
  },
  {
    offset: 0.9,
    color: 'rgba(0, 0, 0, 0.3)',
  },
  {
    offset: 1,
    color: 'rgba(0, 0, 0, 0.4)',
  },
];

export function getGradient(radius: number, shadowOffset = SHADOW_OFFSET_Y, shadowHeight = SHADOW_HEIGHT) {
  const r1 = shadowHeight > radius ? 0 : radius - shadowHeight;
  return new FabricGradient({
    type: 'radial',
    gradientUnits: 'pixels',
    offsetX: radius + shadowOffset,
    offsetY: radius + shadowOffset * 2,
    coords: {
      r1,
      r2: radius + shadowOffset,
    },
    colorStops: gradientColorStops,
  });
}
