import { StaticCanvas } from 'fabric';
import { useEffect } from 'react';

function useRenderCanvasOnPageVisible(fabricCanvas: StaticCanvas | undefined) {
  useEffect(() => {
    if (!fabricCanvas || fabricCanvas.getWidth() === 0 || fabricCanvas.getHeight() === 0) {
      return undefined;
    }

    function onPageVisibilityChange() {
      if (document.visibilityState === 'visible') {
        fabricCanvas?.requestRenderAll();
      }
    }

    document.addEventListener('visibilitychange', onPageVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onPageVisibilityChange);
    };
  }, [fabricCanvas]);

  return fabricCanvas;
}

export default useRenderCanvasOnPageVisible;
