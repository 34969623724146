import { Canvas as FabricCanvas } from 'fabric';
import React, { useContext } from 'react';

export const FabricCanvasContext = React.createContext<FabricCanvas>(null as any);

export function useFabricCanvas() {
  return useContext(FabricCanvasContext);
}

export default useFabricCanvas;
