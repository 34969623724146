import { filters as fabricFilters } from 'fabric';
import { TWebGLPipelineState } from 'fabric/src/filters/typedefs';
import Matrix from 'ml-matrix';

import degrees2Radians from 'editor/src/util/degrees2Radians';

const glsl = (e: TemplateStringsArray) => e;

const m3 = {
  translation: function translation(tx: number, ty: number, ar: number) {
    return [
      [1, 0, 0],
      [0, 1, 0],
      [tx * ar, ty, 1],
    ];
  },

  rotation: function rotation(angleInRadians: number, ar: number) {
    const c = Math.cos(angleInRadians);
    const s = Math.sin(angleInRadians);
    return [
      [c / ar, -s, 0],
      [s / ar, c, 0],
      [0, 0, 1],
    ];
  },

  scaling: function scaling(sx: number, sy: number, ar: number) {
    return [
      [sx * ar, 0, 0],
      [0, sy, 0],
      [0, 0, 1],
    ];
  },
};

export type ConstructorOption = {
  x: number;
  y: number;
  width: number;
  height: number;
  angle: number;
  inside?: boolean;
};

class CropFilter extends fabricFilters.BaseFilter<'CropFilter', ConstructorOption> {
  static override type = 'CropFilter';

  declare x: number;
  declare y: number;
  declare width: number;
  declare height: number;
  declare angle: number;
  declare inside: boolean;
  declare originalWidth: number;
  declare originalHeight: number;

  static override defaults = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    angle: 0,
    inside: true,
    originalWidth: 0,
    originalHeight: 0,
  };

  override getVertexSource(): string {
    return glsl`
      attribute vec2 aPosition;
      uniform mat3 u_transformMatrix;
      uniform int u_inside;
      varying vec2 v_f2TexCoord;
  
      void main() {
        v_f2TexCoord = (u_transformMatrix * vec3(aPosition, 1.0)).xy;
        if (u_inside == 1) {
          gl_Position = vec4(aPosition * 2.0 - 1.0, 0.0, 1.0);
        } else {
          gl_Position = vec4((u_transformMatrix * vec3(aPosition, 1.0)).xy * 2.0 - 1.0, 0.0, 1.0);
        }
      }
    ` as unknown as string;
  }

  protected override getFragmentSource(): string {
    return glsl`
      precision highp float;
      varying vec2 v_f2TexCoord;
      uniform sampler2D u_s2Texture;
  
      void main() {
        gl_FragColor = texture2D(u_s2Texture, v_f2TexCoord);
      }
    ` as unknown as string;
  }

  override getUniformLocations(gl: WebGLRenderingContext, program: WebGLProgram) {
    return {
      transformMatrix: gl.getUniformLocation(program, 'u_transformMatrix'),
      inside: gl.getUniformLocation(program, 'u_inside'),
    };
  }

  override sendUniformData(gl: WebGLRenderingContext, uniformLocations: { [name: string]: WebGLUniformLocation }) {
    const ar = this.originalWidth / this.originalHeight;
    const matrix = Matrix.identity(3, 3)
      .mmul(new Matrix(m3.scaling(this.width, this.height, ar)))
      .mmul(new Matrix(m3.translation(this.x, this.y, ar)))
      .mmul(new Matrix(m3.rotation(degrees2Radians(this.angle), ar)));
    gl.uniformMatrix3fv(uniformLocations.transformMatrix, false, matrix.to1DArray());
    gl.uniform1i(uniformLocations.inside, this.inside ? 1 : 0);
  }

  override applyToWebGL(p: TWebGLPipelineState) {
    this.originalWidth = p.sourceWidth;
    this.originalHeight = p.sourceHeight;

    if (!this.inside) {
      const gl = p.context;
      gl.clearColor(0, 0, 0, 0);
      // eslint-disable-next-line no-bitwise
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    }

    return super.applyToWebGL(p);
  }
}

export default CropFilter;
