import { filters as fabricFilters } from 'fabric';
import { T2DPipelineState, TMatColorMatrix } from 'fabric/src/filters/typedefs';

import { Options } from './types';

class Vintage extends fabricFilters.ColorMatrix {
  static override type = 'Vintage';

  declare value: number;

  calculateMatrix() {
    const coloMatrix = [
      0.62793, 0.32021, -0.03965, 0, 0.03784, 0.02578, 0.64411, 0.03259, 0, 0.02926, 0.0466, -0.08512, 0.52416, 0,
      0.02023, 0, 0, 0, 1, 0,
    ];

    const baseMatrix = [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0];

    const nVal = this.value / 100;

    this.matrix = coloMatrix.map((element, i) => element * nVal + baseMatrix[i] * (1 - nVal)) as TMatColorMatrix;
  }

  override isNeutralState(options: Options) {
    this.calculateMatrix();
    return fabricFilters.BaseFilter.prototype.isNeutralState.call(this, options);
  }

  override applyTo(options: T2DPipelineState) {
    this.calculateMatrix();
    fabricFilters.BaseFilter.prototype.applyTo.call(this, options);
  }

  override toObject() {
    return {
      ...super.toObject(),
      value: this.value,
    };
  }
}

export default Vintage;
