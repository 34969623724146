import { filters as fabricFilters } from 'fabric';
import { TWebGLPipelineState } from 'fabric/src/filters/typedefs';
import { inverse, Matrix } from 'ml-matrix';

import { PerspectiveTransform } from 'editor/src/store/design/types';

export const DEFAULT_TRANSFORM: PerspectiveTransform = {
  tl_x: 0,
  tl_y: 0,
  tr_x: 1,
  tr_y: 0,
  bl_x: 0,
  bl_y: 1,
  br_x: 1,
  br_y: 1,
};

// from http://jsfiddle.net/xmqywx/A6Pgy/297/

const glsl = (e: TemplateStringsArray) => e;

type QuadPos = [{ x: number; y: number }, { x: number; y: number }, { x: number; y: number }, { x: number; y: number }];
function transformationFromQuadCorners(before: QuadPos, after: QuadPos) {
  const B = new Matrix([
    [after[0].x, after[0].y, after[1].x, after[1].y, after[2].x, after[2].y, after[3].x, after[3].y],
  ]).transpose();

  const aPoints = [];
  for (let i = 0; i < before.length; i += 1) {
    aPoints.push([before[i].x, 0, -after[i].x * before[i].x, before[i].y, 0, -after[i].x * before[i].y, 1, 0]);
    aPoints.push([0, before[i].x, -after[i].y * before[i].x, 0, before[i].y, -after[i].y * before[i].y, 0, 1]);
  }
  const A = new Matrix(aPoints);

  return inverse(A).mmul(B).transpose().getRow(0);
}

export type ConstructorOption = {
  transform: PerspectiveTransform;
};

class PerspectiveTransformFilter extends fabricFilters.BaseFilter<'PerspectiveTransformFilter'> {
  static override type = 'PerspectiveTransformFilter';

  declare transform: PerspectiveTransform;

  static override defaults = {
    transform: DEFAULT_TRANSFORM,
  };

  override getVertexSource(): string {
    return glsl`
      attribute vec2 aPosition;
      varying vec2 vTexCoord;
      uniform mat4 uTransformMatrix;
  
      void main() {
        vTexCoord = aPosition;
        gl_Position =  uTransformMatrix * vec4(aPosition * 2.0 - 1.0, 0.0, 1.0);
      }
    ` as unknown as string;
  }

  protected override getFragmentSource(): string {
    return glsl`
      precision highp float;
      varying vec2 vTexCoord;
      uniform sampler2D uTexture;
      void main() {
        gl_FragColor = texture2D(uTexture, vTexCoord);
      }
    ` as unknown as string;
  }

  override getUniformLocations(gl: WebGLRenderingContext, program: WebGLProgram) {
    return {
      transformMatrix: gl.getUniformLocation(program, 'uTransformMatrix'),
    };
  }

  override sendUniformData(gl: WebGLRenderingContext, uniformLocations: { [name: string]: WebGLUniformLocation }) {
    const srcPoints: QuadPos = [
      { x: 0, y: 0 }, // top-left
      { x: 1, y: 0 }, // top-right
      { x: 0, y: 1 }, // bottom-left
      { x: 1, y: 1 }, // bottom-right
    ];

    const dstPoints = [
      { x: this.transform.tl_x, y: this.transform.tl_y },
      { x: this.transform.tr_x, y: this.transform.tr_y },
      { x: this.transform.bl_x, y: this.transform.bl_y },
      { x: this.transform.br_x, y: this.transform.br_y },
    ];

    function trans(p: { x: number; y: number }) {
      p.x = p.x * 2 - 1;
      p.y = p.y * 2 - 1;
    }

    srcPoints.forEach(trans);
    dstPoints.forEach(trans);

    const v = transformationFromQuadCorners(srcPoints, dstPoints as any);
    const transformMatrix = [v[0], v[1], 0, v[2], v[3], v[4], 0, v[5], 0, 0, 0, 0, v[6], v[7], 0, 1];

    gl.uniformMatrix4fv(uniformLocations.transformMatrix, false, transformMatrix);
  }

  override applyToWebGL(p: TWebGLPipelineState) {
    const gl = p.context;
    gl.clearColor(0, 0, 0, 0);
    // eslint-disable-next-line no-bitwise
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    return super.applyToWebGL(p);
  }
}

export default PerspectiveTransformFilter;
