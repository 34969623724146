import { FabricObject } from 'fabric';
import { Polygon } from 'polygon-clipping';

import getClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getClipPath';

import { ObjectRect } from './types';

const EPSILON = 0.0001;

export function areRectEquals(rect1: ObjectRect, rect2: Partial<ObjectRect>, epsilon: number): boolean {
  return (
    Math.abs(rect1.angle - (rect2.angle || 0)) < epsilon &&
    Math.abs(rect1.left - (rect2.left || 0)) < epsilon &&
    Math.abs(rect1.top - (rect2.top || 0)) < epsilon &&
    Math.abs(rect1.width - (rect2.width || 0)) < epsilon &&
    Math.abs(rect1.height - (rect2.height || 0)) < epsilon
  );
}

function getImageClipPath(
  frameRect: ObjectRect,
  imageRect: Partial<ObjectRect>,
  contentClipPolygons: Polygon[],
  forceClipping: boolean,
  contentClipPath: FabricObject | undefined,
) {
  const needsClipping = forceClipping || !areRectEquals(frameRect, imageRect, EPSILON);
  return getClipPath(frameRect, contentClipPolygons, needsClipping, contentClipPath);
}

export default getImageClipPath;
