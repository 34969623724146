import { filters as fabricFilters } from 'fabric';
import { T2DPipelineState, TMatColorMatrix } from 'fabric/src/filters/typedefs';

import { Options } from './types';

class Technicolor extends fabricFilters.ColorMatrix<'Technicolor'> {
  static override type = 'Technicolor';

  declare value: number;

  calculateMatrix() {
    const coloMatrix = [
      1.91252, -0.85453, -0.09155, 0, 0.04624, -0.30878, 1.76589, -0.10601, 0, -0.27589, -0.2311, -0.75018, 1.84759, 0,
      0.12137, 0, 0, 0, 1, 0,
    ];

    const baseMatrix = [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0];

    const nVal = this.value / 100;

    this.matrix = coloMatrix.map((element, i) => element * nVal + baseMatrix[i] * (1 - nVal)) as TMatColorMatrix;
  }

  override isNeutralState(options: Options) {
    this.calculateMatrix();
    return fabricFilters.BaseFilter.prototype.isNeutralState.call(this, options);
  }

  override applyTo(options: T2DPipelineState) {
    this.calculateMatrix();
    fabricFilters.BaseFilter.prototype.applyTo.call(this, options);
  }

  override toObject() {
    return {
      ...super.toObject(),
      value: this.value,
    };
  }
}

export default Technicolor;
