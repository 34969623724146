import isMobile from 'editor/src/store/app/selector/isMobile';
import { showActionBarAction } from 'editor/src/store/app/slice';
import { ActionBarNames } from 'editor/src/store/app/types';
import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getMediaElementByUuid from 'editor/src/store/design/selector/getMediaElementByUuid';
import isPersonalizationLockIgnored from 'editor/src/store/editor/selector/isPersonalizationLockIgnored';
import { setSelectedElementUuidsAction } from 'editor/src/store/editor/slice';
import setSidebarActiveTabByNameOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabByNameOperation';
import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import { MockupRole } from 'editor/src/store/mockup/types';

import { unselectAllImagesAction } from '../../gallery/slice';

import removeAllSelectedMediaElementsOperation from './removeAllSelectedMediaElementsOperation';
import selectTextElementOperation from './selectTextElementOperation';
import setFocusedMediaElementUuidOperation from './setFocusedMediaElementUuidOperation';

const addSelectedMediaElementOperation =
  (uuid: number, suggestGallery = true) =>
  (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    if (
      state.editor.selectedElementUuids.length <= 1 &&
      state.editor.selectedElementUuids.find((elementUid) => elementUid === uuid)
    ) {
      return;
    }

    batch(() => {
      dispatch(removeAllSelectedMediaElementsOperation());
      dispatch(setFocusedMediaElementUuidOperation(undefined));
      dispatch(setSelectedElementUuidsAction([uuid]));

      const selectedElement = getMediaElementByUuid(state, uuid);

      if (selectedElement?.type === 'image' && state.gallery.selectedImagesIds.length > 0) {
        dispatch(unselectAllImagesAction());
      } else if (selectedElement?.type === 'text') {
        dispatch(selectTextElementOperation());
      }

      if (suggestGallery) {
        // if image has no frame show gallery
        const ignorePersonalizationLock = isPersonalizationLockIgnored(state);
        if (
          selectedElement?.type === 'image' &&
          selectedElement.role !== MockupRole.Placeholder &&
          !selectedElement.imageId &&
          (!selectedElement.personalizationLocked || ignorePersonalizationLock)
        ) {
          if (isMobile(state)) {
            dispatch(showActionBarAction(ActionBarNames.ReplaceImage));
          } else {
            dispatch(setSidebarActiveTabByNameOperation(TAB_NAMES.GALLERY));
          }
        }
      }
    });
  };

export default addSelectedMediaElementOperation;
