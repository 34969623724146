import React from 'react';

import { Coords, BlankArea } from 'editor/src/store/design/types';

import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricRectComponent from 'editor/src/component/EditorArea/fabricComponents/FabricRectComponent';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';
import useCanvasRotation from 'editor/src/component/EditorArea/useCanvasRotation';
import { LIGHT_SHADOW } from 'editor/src/component/SpreadPreview/addPageElement';

interface Props {
  blank: BlankArea;
  pageCoords: Coords;
  canvasRotation: CanvasRotation;
  showBlankAreaShadowOnly: boolean;
}

function BlankAreaElement({ blank, pageCoords, canvasRotation, showBlankAreaShadowOnly }: Props) {
  const { mm2px } = useFabricUtils();

  const rotatedPoint = useCanvasRotation(
    canvasRotation,
    pageCoords.left + mm2px(blank.x),
    pageCoords.top + mm2px(blank.y),
  );

  return (
    <FabricRectComponent
      left={rotatedPoint.x}
      top={rotatedPoint.y}
      width={mm2px(blank.width)}
      height={mm2px(blank.height)}
      evented={false}
      selectable={false}
      fill={showBlankAreaShadowOnly ? '#fff' : 'rgba(34, 34, 34, 0.05)'}
      zIndex={zIndex.BLANK}
      objectCaching={false}
      shadow={showBlankAreaShadowOnly ? LIGHT_SHADOW : undefined}
    />
  );
}

export default React.memo(BlankAreaElement);
