import React, { useImperativeHandle, useState } from 'react';

import CustomFabricCircle, { ICircleOptions } from 'editor/src/fabric/CustomFabricCircle';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import useControlVisibility, { ControlVisibility } from './useControlVisibility';
import useEvent, { EventHandler } from './useEvent';
import useEvents from './useEvents';
import useObjectProps from './useObjectProps';
import useObjectUpdate from './useObjectUpdate';

export interface Props extends ICircleOptions {
  uuid?: number;
  events?: { [eventType: string]: EventHandler };
  onMouseUp?: EventHandler;
  onSelected?: EventHandler;
  onMove?: EventHandler;
  controlVisibility?: ControlVisibility;
  onMouseDownBefore?: EventHandler;
  onMouseMove?: EventHandler;
  onModified?: EventHandler;
}

function FabricCircleComponent(props: Props, ref: React.Ref<CustomFabricCircle>) {
  const fabricCanvas = useFabricCanvas();
  const [element] = useState(() => new CustomFabricCircle());
  const {
    events,
    controlVisibility,
    onMouseUp,
    onSelected,
    onMove,
    onMouseDownBefore,
    onMouseMove,
    onModified,
    ...fabricProps
  } = props;
  useObjectProps(element, fabricProps);

  useImperativeHandle(ref, () => element);

  useEvents(element, events);
  useEvent(element, 'moving', onMove);
  useEvent(element, 'selected', onSelected);
  useEvent(element, 'mouseup', onMouseUp);
  useEvent(element, 'mousedown', onMouseDownBefore);
  useEvent(element, 'mousemove', onMouseMove);
  useEvent(element, 'modified', onModified);
  useControlVisibility(element, controlVisibility);

  useObjectUpdate(fabricCanvas, element);

  return null;
}

export default React.memo(React.forwardRef(FabricCircleComponent));
