import { Circle as FabricCircle } from 'fabric';

export interface ICircleOptions extends Partial<FabricCircle> {
  zIndex: number;
}

class CustomFabricCircle extends FabricCircle<ICircleOptions> {
  declare zIndex: number;
}

export default CustomFabricCircle;
