import { FabricObject, Group as FabricGroup } from 'fabric';
import { Polygon } from 'polygon-clipping';
import React, { useEffect, useMemo } from 'react';

import { Coords, MediaBox, MediaElement } from 'editor/src/store/design/types';
import { isEmbedded } from 'editor/src/store/watchers/useDisplayMode';

import CustomFabricGroup from 'editor/src/fabric/CustomFabricGroup';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';
import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricImageCanvasComponent from 'editor/src/component/EditorArea/fabricComponents/FabricImageCanvasComponent';
import useMirrorCanvas from 'editor/src/component/EditorArea/Spread/Mirror/useMirrorCanvas';
import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';
import { polygonToFabricPolygon } from 'editor/src/component/EditorArea/utils/polygonsUtils';

import applyWhiteUnderbase from './whiteUnderbaseHelper';

interface Props {
  pageCoords: Coords;
  mediaBox: MediaBox;
  contentClippingPolygons: Polygon[];
  mediaElements: MediaElement[];
  contentClipPath: FabricObject | undefined;
}

const ratio = window.devicePixelRatio;

function WhiteUnderbaseElements({
  pageCoords,
  mediaBox,
  contentClippingPolygons,
  mediaElements,
  contentClipPath,
}: Props) {
  const fabricCanvas = useFabricCanvas();
  const uuidsForWhiteUnderbase = useMemo(() => {
    const uuids = new Set<number>();
    mediaElements.forEach((mediaElement) => {
      uuids.add(mediaElement.uuid);
    });
    return uuids;
  }, [mediaElements]);

  const { mirrorCanvas, mediaBoxBox } = useMirrorCanvas(pageCoords, mediaBox, undefined, uuidsForWhiteUnderbase);
  const { mm2px } = useFabricUtils();
  const renderRatio = ratio * ratio;

  const clipPath = useMemo(
    () =>
      new CustomFabricGroup(
        contentClippingPolygons.map((p) => polygonToFabricPolygon(p)),
        {
          absolutePositioned: true,
          absoluteClipPath: true, // workaround to achieve absolutely positioned clipping. It was broken in fabric 6
        } as Partial<FabricGroup>,
      ),
    [pageCoords, mm2px, contentClippingPolygons],
  );
  clipPath.clipPath = contentClipPath;

  useEffect(() => {
    function onRender() {
      applyWhiteUnderbase(mirrorCanvas);
    }

    mirrorCanvas.on('after:render', onRender);
    fabricCanvas.requestRenderAll();
    return () => {
      mirrorCanvas.off('after:render', onRender);
    };
  }, [mirrorCanvas]);

  if (isEmbedded) {
    return null;
  }

  return uuidsForWhiteUnderbase.size ? (
    <FabricImageCanvasComponent
      sourceCanvas={mirrorCanvas}
      getCrossOrigin={() => 'anonymous'}
      left={mediaBoxBox.x}
      top={mediaBoxBox.y}
      scaleX={1 / renderRatio}
      scaleY={1 / renderRatio}
      width={mediaBoxBox.width * renderRatio}
      height={mediaBoxBox.height * renderRatio}
      clipPath={clipPath}
      evented={false}
      selectable={false}
      zIndex={zIndex.MEDIA + mediaElements.length + 1}
      objectCaching={false}
    />
  ) : null;
}

export default React.memo(WhiteUnderbaseElements);
