import { Canvas as FabricCanvas, Point as FabricPoint } from 'fabric';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { MediaImage } from 'editor/src/store/design/types';

import limitPrecision from 'editor/src/util/limitPrecision';

import { KEYBOARD_ELEMENT } from 'editor/src/component/EditorArea//useCanvasDimensions';
import orderObjects from 'editor/src/component/EditorArea/orderObjects';
import useRenderCanvasOnPageVisible from 'editor/src/component/EditorArea/useRenderCanvasOnPageVisible';

export const CANVAS_ID = 'canvas-editor';

const useCommonCanvasUtils = (isMobile: boolean, element: MediaImage) => {
  const canvasDiv = useRef<HTMLDivElement>(null);
  const [canvasDimensions, setCanvasDimensions] = useState<{ width: number; height: number }>();

  const [fabricCanvas, setFabricCanvas] = useState<FabricCanvas>();

  useEffect(() => {
    const canvas = new FabricCanvas(CANVAS_ID, {
      selection: false,
      renderOnAddRemove: false,
      preserveObjectStacking: true, // to avoid having the active elements always on top
      uniScaleKey: null,
      centeredKey: null,
    });

    (window as any).fabricCanvas = canvas;

    canvas.on('before:render', () => orderObjects(canvas));
    setFabricCanvas(canvas);
  }, []);

  useRenderCanvasOnPageVisible(fabricCanvas);

  const recalculateCanvasSize = useCallback(() => {
    if (!canvasDiv.current || !fabricCanvas) {
      return false;
    }
    const { clientHeight, clientWidth } = canvasDiv.current;
    if (clientWidth === 0 || clientHeight === 0) {
      return false;
    }

    const tagName = document.activeElement?.tagName;
    if (
      clientWidth === fabricCanvas.getWidth() &&
      // no resize on mobile when opening the keyboard
      (clientHeight === fabricCanvas.getHeight() || (tagName && KEYBOARD_ELEMENT.has(tagName)))
    ) {
      return true;
    }

    fabricCanvas.setDimensions({ width: clientWidth, height: clientHeight });
    setCanvasDimensions({ width: clientWidth, height: clientHeight });
    return true;
  }, [fabricCanvas]);

  useEffect(() => {
    recalculateCanvasSize();
  }, [fabricCanvas]);

  useEffect(() => {
    const recalculateCanvasSizeDebounced = debounce(recalculateCanvasSize, 100, { trailing: true });
    window.addEventListener('resize', recalculateCanvasSizeDebounced);
    window.addEventListener('resizeCanvas', recalculateCanvasSizeDebounced);
    return () => {
      window.removeEventListener('resize', recalculateCanvasSizeDebounced);
      window.removeEventListener('resizeCanvas', recalculateCanvasSizeDebounced);
    };
  }, [recalculateCanvasSize]);

  const fabricUtils = useMemo(() => {
    let pxSize = 1;
    let mmSize = 1;
    const canvasWidth = canvasDimensions?.width ?? 0;
    const canvasHeight = canvasDimensions?.height ?? 0;

    if (canvasWidth / element.width > canvasHeight / element.height) {
      pxSize = canvasHeight;
      mmSize = element.height * 2;
    } else {
      pxSize = canvasWidth;
      mmSize = element.width * 2;
    }

    // fabric has 14 digits precision internally
    return {
      px2mm: (size: number) => limitPrecision(size * (mmSize / pxSize), 13),
      mm2px: (size: number) => limitPrecision(size * (pxSize / mmSize), 13),
      setup: canvasWidth > 0 && canvasHeight > 0,
    };
  }, [canvasDimensions?.width, canvasDimensions?.height, element.width, element.height, isMobile]);

  useEffect(() => {
    if (fabricUtils.setup) {
      fabricCanvas?.absolutePan(
        new FabricPoint({
          x: (fabricUtils.mm2px(element.width) - fabricCanvas.getWidth()) / 2,
          y: (fabricUtils.mm2px(element.height) - fabricCanvas.getHeight()) / 2,
        }),
      );
    }
  }, [fabricCanvas, fabricUtils]);

  return {
    canvasDiv,
    fabricCanvas,
    fabricUtils,
  };
};

export default useCommonCanvasUtils;
