import { filters as fabricFilters } from 'fabric';
import { type T2DPipelineState, TMatColorMatrix } from 'fabric/src/filters/typedefs';

import { Options } from './types';

class Polaroid extends fabricFilters.ColorMatrix {
  static override type = 'Polaroid';

  declare value: number;

  calculateMatrix() {
    const coloMatrix = [
      1.438, -0.062, -0.062, 0, 0, -0.122, 1.378, -0.122, 0, 0, -0.016, -0.016, 1.483, 0, 0, 0, 0, 0, 1, 0,
    ];

    const baseMatrix = [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0];

    const nVal = this.value / 100;

    this.matrix = coloMatrix.map((element, i) => element * nVal + baseMatrix[i] * (1 - nVal)) as TMatColorMatrix;
  }

  override isNeutralState(options: Options) {
    this.calculateMatrix();
    return fabricFilters.BaseFilter.prototype.isNeutralState.call(this, options);
  }

  override applyTo(options: T2DPipelineState) {
    this.calculateMatrix();
    fabricFilters.BaseFilter.prototype.applyTo.call(this, options);
  }

  override toObject() {
    return {
      ...super.toObject(),
      value: this.value,
    };
  }
}

export default Polaroid;
