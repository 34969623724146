import { Path as FabricPath } from 'fabric';
import React, { useImperativeHandle, useMemo } from 'react';

import CustomFabricObject from 'editor/src/fabric/CustomFabricObject';
import CustomFabricPath from 'editor/src/fabric/CustomFabricPath';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import useObjectProps from './useObjectProps';
import useObjectUpdate from './useObjectUpdate';

interface Props extends Partial<FabricPath> {
  zIndex?: number;
  elementPath: string;
  getFabricOptionsOnUpdate?: (element: CustomFabricPath) => Partial<FabricPath>;
}

function FabricPathComponent(props: Props, ref: React.Ref<CustomFabricObject>) {
  const fabricCanvas = useFabricCanvas();
  const { elementPath, getFabricOptionsOnUpdate, ...fabricProps } = props;

  const element = useMemo(() => new CustomFabricPath(elementPath), [elementPath]);
  useImperativeHandle(ref, () => element);
  useObjectProps(element, fabricProps);

  if (getFabricOptionsOnUpdate) {
    element.set(getFabricOptionsOnUpdate(element));
  }

  useObjectUpdate(fabricCanvas, element);

  return null;
}

export default React.memo(React.forwardRef(FabricPathComponent));
