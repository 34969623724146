import { filters as fabricFilters } from 'fabric';
import { T2DPipelineState } from 'fabric/src/filters/typedefs';

interface Props {
  brightness: number;
}

class Brightness extends fabricFilters.BaseFilter<'Brightness', Props> {
  declare brightness: number;

  static override type = 'Brightness';

  static override defaults = {
    brightness: 0,
  };

  static override uniformLocations = ['uBrightness'];

  override getFragmentSource() {
    return (
      'precision highp float;\n' +
      'uniform sampler2D uTexture;\n' +
      'uniform float uBrightness;\n' +
      'varying vec2 vTexCoord;\n' +
      'void main() {\n' +
      'vec4 color = texture2D(uTexture, vTexCoord);\n' +
      'color.rgb += uBrightness;\n' +
      'gl_FragColor = color;\n' +
      '}'
    );
  }

  override applyTo2d(options: T2DPipelineState) {
    if (this.brightness === 0) {
      return;
    }
    const { imageData } = options;
    const { data } = imageData;
    const len = data.length;

    for (let i = 0; i < len; i += 4) {
      data[i] += this.brightness;
      data[i + 1] += this.brightness;
      data[i + 2] += this.brightness;
    }
  }

  override getUniformLocations(gl: WebGLRenderingContext, program: WebGLProgram) {
    return {
      uBrightness: gl.getUniformLocation(program, 'uBrightness'),
    };
  }

  override sendUniformData(gl: WebGLRenderingContext, uniformLocations: { [name: string]: WebGLUniformLocation }) {
    gl.uniform1f(uniformLocations.uBrightness, this.brightness / 100);
  }

  override isNeutralState() {
    return this.brightness === 0;
  }

  override toObject() {
    return {
      ...super.toObject(),
      brightness: this.brightness,
    };
  }
}

export default Brightness;
