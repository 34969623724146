import { Path as FabricPath } from 'fabric';

interface ICustomFabricPath extends Partial<FabricPath> {
  zIndex: number;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
class CustomFabricPath extends FabricPath<ICustomFabricPath> {
  declare zIndex: number;
}

export default CustomFabricPath;
