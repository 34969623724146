import { Canvas as FabricCanvas, FabricObject } from 'fabric';
import { useEffect, useRef } from 'react';

import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import addNewTextToPageOperation from 'editor/src/store/design/operation/addNewTextToPageOperation';
import applyToMultipleMediaOperation from 'editor/src/store/design/operation/applyToMultipleMediaOperation';
import clearImagesOrRemoveElementsOperation from 'editor/src/store/design/operation/clearImagesOrRemoveElementsOperation';
import copyMediaElementOperation from 'editor/src/store/design/operation/copyMediaElementOperation';
import updateMediaElementOperation, {
  MediaUpdateActionName,
} from 'editor/src/store/design/operation/updateMediaElementOperation';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { getStructureIndexByElementUuid } from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import getStructureIndexesOfSelectedElements from 'editor/src/store/design/selector/getStructureIndexesOfSelectedElements';
import { MediaLine } from 'editor/src/store/design/types';
import isMediaMockupPlaceholder from 'editor/src/store/design/util/isMediaMockupPlaceholder';
import removeAllSelectedMediaElementsOperation from 'editor/src/store/editor/operation/removeAllSelectedMediaElementsOperation';
import getClipboardElementUuid from 'editor/src/store/editor/selector/getClipboardElementUuid';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import getSelectedElementUuids from 'editor/src/store/editor/selector/getSelectedElementUuids';
import isAddElementsAllowed from 'editor/src/store/editor/selector/isAddElementsAllowed';
import isRemoveElementsAllowed from 'editor/src/store/editor/selector/isRemoveElementsAllowed';
import { setClipboardElementUuidAction as setClipboardElementUuidOperation } from 'editor/src/store/editor/slice';
import hideSidebarOperation from 'editor/src/store/editorModules/sidebar/operation/hideSidebarOperation';
import redoStoreOperation from 'editor/src/store/editorModules/undoRedo/operation/redoStoreOperation';
import undoStoreOperation from 'editor/src/store/editorModules/undoRedo/operation/undoStoreOperation';
import { useStore, useDispatch } from 'editor/src/store/hooks';

import { isFabricPathText } from 'editor/src/fabric/FabricPathText';
import { RootState } from 'editor/src/store';
import limitPrecision from 'editor/src/util/limitPrecision';

export const AVOID_TARGETS = new Set(['INPUT', 'TEXTAREA', 'SELECT', 'BUTTON']);

export function isKeyboardUsed(e: Event) {
  return !(e.target instanceof Element) || AVOID_TARGETS.has(e.target.nodeName);
}

function useKeyboardShortcuts(fabricCanvas: FabricCanvas | undefined) {
  const dispatch = useDispatch();
  const store = useStore();

  const lastCopySource = useRef<'internal' | 'external'>();
  const dataFromCLipboard = useRef<string | undefined>(undefined);

  function onArrowKey(state: RootState, attr: 'x' | 'y', sign: 1 | -1, e: KeyboardEvent) {
    const selectedElementsIndexes = getStructureIndexesOfSelectedElements(state);

    // we might want to implement a method to update multiple elements at once
    batch(() => {
      selectedElementsIndexes.forEach((address) => {
        const element = getMediaElement(state, address);
        if (element && !element.locked) {
          if (element.type !== 'line') {
            const value = limitPrecision(1 / (fabricCanvas?.getZoom() ?? 1), 1) * sign;
            const update = {
              [attr]: element[attr] + (e.shiftKey ? value * 10 : value),
            };
            dispatch(updateMediaElementOperation(address, update, MediaUpdateActionName.MOVED, e.repeat));
          } else {
            const update: Partial<MediaLine> = {};
            const shiftValue = e.shiftKey ? sign * 10 : sign;
            if (attr === 'x') {
              update.x1 = element.x1 + shiftValue;
              update.x2 = element.x2 + shiftValue;
            } else {
              update.y1 = element.y1 + shiftValue;
              update.y2 = element.y2 + shiftValue;
            }
            dispatch(updateMediaElementOperation(address, update, MediaUpdateActionName.MOVED, e.repeat));
          }
        }
      });
    });
  }

  const isPressingCmd = useRef(false);
  const isPressingShift = useRef(false);

  function onKeyDown(e: KeyboardEvent) {
    if (isKeyboardUsed(e)) {
      return;
    }

    isPressingCmd.current = e.metaKey || e.ctrlKey;
    isPressingShift.current = e.shiftKey;

    const state = store.getState();

    switch (e.key) {
      case 'Escape': {
        const uuids = getSelectedElementUuids(state);

        if (uuids.length) {
          const object = fabricCanvas?.getActiveObject();
          if (object) {
            if (isFabricPathText(object) && object.isEditing) {
              object.fire('editing:exited');
            } else {
              (object as FabricObject).fire('object:escape' as any);
            }
          } else {
            dispatch(removeAllSelectedMediaElementsOperation());
            fabricCanvas?.discardActiveObject();
          }
        } else {
          dispatch(hideSidebarOperation());
        }
        break;
      }
      case 'ArrowUp':
        onArrowKey(state, 'y', -1, e);
        break;
      case 'ArrowDown':
        onArrowKey(state, 'y', 1, e);
        break;
      case 'ArrowLeft':
        onArrowKey(state, 'x', -1, e);
        break;
      case 'ArrowRight':
        onArrowKey(state, 'x', 1, e);
        break;
      case 'd': {
        // to allow the key up event to be captured
        if (isPressingCmd.current) {
          e.preventDefault();
        }
        break;
      }
      case 'z':
        if (isPressingCmd.current) {
          e.preventDefault();
          if (isPressingShift.current) {
            dispatch(redoStoreOperation());
          } else {
            dispatch(undoStoreOperation());
          }
        }
        break;
      case 'Z':
        if (isPressingCmd.current && isPressingShift.current) {
          e.preventDefault();
          dispatch(redoStoreOperation());
        }
        break;
      default:
        break;
    }
  }

  function onKeyUp(e: KeyboardEvent) {
    if (isKeyboardUsed(e)) {
      return;
    }

    const state = store.getState();
    switch (e.key) {
      case 'Delete':
      case 'Backspace': {
        const selectedElementsIndexes = getStructureIndexesOfSelectedElements(state);
        const selectedElement =
          selectedElementsIndexes.length > 0 ? getMediaElement(state, selectedElementsIndexes[0]) : undefined;
        const removeElementAllowed = isRemoveElementsAllowed(state);
        if (selectedElement && removeElementAllowed && !isMediaMockupPlaceholder(selectedElement)) {
          dispatch(clearImagesOrRemoveElementsOperation(selectedElementsIndexes));
        }
        break;
      }
      case 'Enter': {
        const object = fabricCanvas?.getActiveObject();
        if (object) {
          // TODO add separate type for custom events
          object.fire('object:enter' as any);
        }
        break;
      }
      case 'd': {
        if (isPressingCmd.current) {
          const addElementsAllowed = isAddElementsAllowed(state);
          if (addElementsAllowed) {
            const selectedElementsIndexes = getStructureIndexesOfSelectedElements(state);
            dispatch(applyToMultipleMediaOperation(selectedElementsIndexes, copyMediaElementOperation));
          }
        }
        break;
      }
      default:
        break;
    }
  }

  function onCopy() {
    const state = store.getState();
    const selectedElementUid = getSelectedElementUuids(state)[0];
    if (selectedElementUid) {
      dispatch(setClipboardElementUuidOperation({ uuid: selectedElementUid }));
      lastCopySource.current = 'internal';
      dataFromCLipboard.current = undefined;
    }
  }

  function onPaste(e: Event) {
    if (isKeyboardUsed(e)) {
      return;
    }

    const state = store.getState();
    const spreadIndex = getCurrentSpreadIndex(state);
    const clipboardEvent = e as ClipboardEvent;

    if (lastCopySource.current === 'internal') {
      // Handle pasting from the internal copy
      const clipboardElementUid = getClipboardElementUuid(state);
      const clipboardElementStructureIndex = clipboardElementUid
        ? getStructureIndexByElementUuid(state.design.designData, clipboardElementUid)
        : undefined;

      if (clipboardElementStructureIndex !== undefined) {
        dispatch(
          copyMediaElementOperation(clipboardElementStructureIndex, {
            spreadIndex: getCurrentSpreadIndex(state),
          }),
        );
      }
    } else {
      // Handle pasting from the external copy (system clipboard)
      const clipboardText = clipboardEvent.clipboardData?.getData('text/plain');
      if (clipboardText) {
        dataFromCLipboard.current = clipboardText;
        dispatch(addNewTextToPageOperation(spreadIndex, 0, dataFromCLipboard.current));
      }
    }
  }

  function handleVisibilityChange() {
    if (document.visibilityState === 'hidden') {
      lastCopySource.current = 'external';
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('paste', onPaste);
    window.addEventListener('copy', onCopy);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
      window.removeEventListener('paste', onPaste);
      window.removeEventListener('copy', onCopy);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [store, fabricCanvas]);
}

export default useKeyboardShortcuts;
