import { filters as fabricFilters } from 'fabric';
import { T2DPipelineState } from 'fabric/src/filters/typedefs';

import { Options } from './types';

interface Props extends T2DPipelineState {
  hue?: number;
  rotation: number;
}

class Hue extends fabricFilters.ColorMatrix<'Hue', Props> {
  static override type = 'Hue';

  declare hue: number;
  declare rotation: number;

  calculateMatrix() {
    const rad = (this.rotation / 180) * Math.PI;
    const cos = Math.cos(rad);
    const sin = Math.sin(rad);
    const aThird = 1 / 3;
    const aThirdSqtSin = Math.sqrt(aThird) * sin;
    const OneMinusCos = 1 - cos;
    this.matrix = [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0];
    this.matrix[0] = cos + OneMinusCos / 3;
    this.matrix[1] = aThird * OneMinusCos - aThirdSqtSin;
    this.matrix[2] = aThird * OneMinusCos + aThirdSqtSin;
    this.matrix[5] = aThird * OneMinusCos + aThirdSqtSin;
    this.matrix[6] = cos + aThird * OneMinusCos;
    this.matrix[7] = aThird * OneMinusCos - aThirdSqtSin;
    this.matrix[10] = aThird * OneMinusCos - aThirdSqtSin;
    this.matrix[11] = aThird * OneMinusCos + aThirdSqtSin;
    this.matrix[12] = cos + aThird * OneMinusCos;
  }

  override isNeutralState(options: Options) {
    this.calculateMatrix();
    return fabricFilters.BaseFilter.prototype.isNeutralState.call(this, options);
  }

  override applyTo(options: T2DPipelineState) {
    this.calculateMatrix();
    fabricFilters.BaseFilter.prototype.applyTo.call(this, options);
  }

  override toObject() {
    return {
      ...super.toObject(),
      hue: this.hue,
    };
  }
}

export default Hue;
