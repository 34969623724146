import React, { useImperativeHandle, useState } from 'react';

import CustomFabricText, { IFabricText } from 'editor/src/fabric/CustomFabricText';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import useObjectProps from './useObjectProps';
import useObjectUpdate from './useObjectUpdate';

interface Props extends IFabricText {
  text: string;
}

function FabricTextComponent(props: Props, ref: React.Ref<CustomFabricText>) {
  const fabricCanvas = useFabricCanvas();

  const [element] = useState(() => new CustomFabricText(props.text));
  useImperativeHandle(ref, () => element);
  useObjectProps(element, props);
  useObjectUpdate(fabricCanvas, element);

  return null;
}

export default React.memo(React.forwardRef(FabricTextComponent));
