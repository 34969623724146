import { Line as FabricLine } from 'fabric';
import React, { useImperativeHandle, useMemo } from 'react';

import CustomFabricLine from 'editor/src/fabric/CustomFabricLine';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import useObjectProps from './useObjectProps';
import useObjectUpdate from './useObjectUpdate';

export interface Props extends Partial<FabricLine> {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  zIndex?: number;
  angle?: number;
}

function FabricLineComponent(props: Props, ref: React.Ref<CustomFabricLine>) {
  const fabricCanvas = useFabricCanvas();

  const { ...fabricProps } = props;

  const element = useMemo(() => new CustomFabricLine([0, 0, 0, 0]), []);
  useObjectProps(element, fabricProps);
  useImperativeHandle(ref, () => element);
  useObjectUpdate(fabricCanvas, element);

  return null;
}

export default React.memo(React.forwardRef(FabricLineComponent));
