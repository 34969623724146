import { filters as fabricFilters } from 'fabric';
import { T2DPipelineState, TMatColorMatrix } from 'fabric/src/filters/typedefs';

import { Options } from './types';

class Kodachrome extends fabricFilters.ColorMatrix {
  static override type = 'Kodachrome';

  declare value: number;

  calculateMatrix() {
    const coloMatrix = [
      1.12855, -0.39673, -0.03992, 0, 0.24991, -0.16404, 1.08352, -0.05498, 0, 0.09698, -0.16786, -0.56034, 1.60148, 0,
      0.13972, 0, 0, 0, 1, 0,
    ];

    const baseMatrix = [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0];

    const nVal = this.value / 100;

    this.matrix = coloMatrix.map((element, i) => element * nVal + baseMatrix[i] * (1 - nVal)) as TMatColorMatrix;
  }

  override isNeutralState(options: Options) {
    this.calculateMatrix();
    return fabricFilters.BaseFilter.prototype.isNeutralState.call(this, options);
  }

  override applyTo(options: T2DPipelineState) {
    this.calculateMatrix();
    fabricFilters.BaseFilter.prototype.applyTo.call(this, options);
  }

  override toObject() {
    return {
      ...super.toObject(),
      value: this.value,
    };
  }
}

export default Kodachrome;
