import { createSlice } from '@reduxjs/toolkit';

import setFlatPreviewFailedReducer from './reducer/flatPreviewFailedReducer';
import resetSpreadPreviewReducer from './reducer/resetSpreadPreviewReducer';
import setActivePreviewReducer from './reducer/setActivePreviewReducer';
import setAdvancedFlatPreviewsReducer from './reducer/setAdvancedFlatPreviewsReducer';
import setFlatPreviewAvailableReducer from './reducer/setFlatPreviewAvailableReducer';
import setFlatPreviewReadyReducer from './reducer/setFlatPreviewReadyReducer';
import setFlatPreviewsReducer from './reducer/setFlatPreviewsReducer';
import setPreviewStatusReducer from './reducer/setPreviewStatusReducer';
import setSpreadPreviewReducer from './reducer/setSpreadPreviewReducer';
import setThe3dPreviewAvailableReducer from './reducer/setThe3dPreviewAvailableReducer';
import setThe3dPreviewReducer from './reducer/setThe3dPreviewReducer';
import { PreviewState, PreviewStatus } from './types';

export const previewInitialState: PreviewState = {
  flatPreviewAvailable: false,
  the3dPreviewAvailable: false,
  activePreview: undefined,
  status: PreviewStatus.INIT,
  flatPreviews: [],
  advancedFlatPreviews: {},
  the3dPreview: undefined,
  spreadPreview: {},
};

export const slice = createSlice({
  name: 'preview',
  initialState: previewInitialState,
  reducers: {
    setFlatPreviewAvailableAction: setFlatPreviewAvailableReducer,
    setThe3dPreviewAvailableAction: setThe3dPreviewAvailableReducer,
    setSpreadPreviewAction: setSpreadPreviewReducer,
    resetSpreadPreviewAction: resetSpreadPreviewReducer,
    setActivePreviewAction: setActivePreviewReducer,
    setPreviewStatusAction: setPreviewStatusReducer,
    setFlatPreviewsAction: setFlatPreviewsReducer,
    setAdvancedFlatPreviewsAction: setAdvancedFlatPreviewsReducer,
    setThe3dPreviewAction: setThe3dPreviewReducer,
    setFlatPreviewReadyAction: setFlatPreviewReadyReducer,
    setFlatPreviewFailedAction: setFlatPreviewFailedReducer,
  },
});

export const {
  setFlatPreviewAvailableAction,
  setThe3dPreviewAvailableAction,
  setActivePreviewAction,
  setPreviewStatusAction,
  setFlatPreviewsAction,
  setSpreadPreviewAction,
  resetSpreadPreviewAction,
  setThe3dPreviewAction,
  setFlatPreviewReadyAction,
  setFlatPreviewFailedAction,
  setAdvancedFlatPreviewsAction,
} = slice.actions;

const editorModules = slice.reducer;

export default editorModules;
