import { createSlice } from '@reduxjs/toolkit';

import setActiveIntroStepReducer from './reducer/setActiveIntroStepReducer';
import setIntroTourStepsReducer from './reducer/setIntroTourStepsReducer';
import setTourPrintAreasReducer from './reducer/setTourPrintAreasReducer';
import { TourState } from './types';

export const tourInitialState: TourState = {
  printAreas: {},
  introSteps: [],
  activeIntroTourStep: undefined,
};

export const slice = createSlice({
  name: 'tour',
  initialState: tourInitialState,
  reducers: {
    setTourPrintAreasAction: setTourPrintAreasReducer,
    setIntroTourStepsAction: setIntroTourStepsReducer,
    setActiveIntroStepAction: setActiveIntroStepReducer,
  },
});

export const { setTourPrintAreasAction, setIntroTourStepsAction, setActiveIntroStepAction } = slice.actions;

const tourReducer = slice.reducer;

export default tourReducer;
