import { TOriginX, TOriginY } from 'fabric/src/typedefs';

import { Coords, MediaLine } from 'editor/src/store/design/types';

import zIndex from 'editor/src/component/EditorArea/Spread/zIndex';

const getCoordinates = (elementData: MediaLine, { left, top }: Coords, mm2px: (n: number) => number) => ({
  x1: left + mm2px(elementData.x1),
  y1: top + mm2px(elementData.y1),
  x2: left + mm2px(elementData.x2),
  y2: top + mm2px(elementData.y2),
});

const lineElementDataToFabricProps = (
  elementData: MediaLine,
  elementIndex: number,
  pageCoords: Coords,
  mm2px: (n: number) => number,
) => ({
  zIndex: zIndex.MEDIA + elementIndex,
  hasControls: false,
  hasBorders: false,
  strokeWidth: mm2px(elementData.strokeWidth),
  stroke: elementData.stroke,
  padding: 5,
  perPixelTargetFind: true,
  originX: 'center' as TOriginX,
  originY: 'center' as TOriginY,
  strokeLineCap: (elementData.rounded ? 'round' : 'butt') as CanvasLineCap,
  strokeDashArray: elementData.dashPattern
    ? [
        mm2px(elementData.dashPattern.dash * elementData.strokeWidth),
        mm2px(elementData.dashPattern.gap * elementData.strokeWidth),
      ]
    : undefined,
  ...getCoordinates(elementData, pageCoords, mm2px),
});

export default lineElementDataToFabricProps;
