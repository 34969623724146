import { PayloadAction } from '@reduxjs/toolkit';

import { TourState } from 'editor/src/store/editorModules/tour/types';

import { InboundPostMessage } from 'editor/src/util/postMessages/messages';

const setTourPrintAreasReducer = (
  state: TourState,
  action: PayloadAction<InboundPostMessage['editor.setElementsToHighlight']['printAreas']>,
) => {
  state.printAreas = action.payload;
};

export default setTourPrintAreasReducer;
