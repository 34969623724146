import { filters as fabricFilters, Color as FabricColor } from 'fabric';
import { TWebGLPipelineState } from 'fabric/src/filters/typedefs';

const glsl = (e: TemplateStringsArray) => e;

export type ConstructorOption = {
  shadowColor: string;
  scale: number;
};

class ShadowFilter extends fabricFilters.BaseFilter<'ShadowFilter', ConstructorOption> {
  static override type = 'ShadowFilter';

  declare shadowColor: string;
  declare scale: number;

  static override defaults = {
    shadowColor: '#000000',
    scale: 1,
  };

  override getVertexSource(): string {
    return glsl`
      attribute vec2 aPosition;
      varying vec2 v_f2TexCoord;
  
      void main() {
        v_f2TexCoord = aPosition;
        gl_Position = vec4((aPosition * 2.0 - 1.0), 0.0, 1.0);
      }
    ` as unknown as string;
  }

  protected override getFragmentSource(): string {
    return glsl`
      precision highp float;
      varying vec2 v_f2TexCoord;
      uniform sampler2D u_s2Texture;
      uniform vec4 uColor;
      uniform float u_scale;
      
      void main() {
        float offset = 1.0 - 1.0 / u_scale;
        if (v_f2TexCoord.x < offset / 2.0 || v_f2TexCoord.x > 1.0 - offset / 2.0 || v_f2TexCoord.y < offset / 2.0 || v_f2TexCoord.y > 1.0 - offset / 2.0) {
          discard;
          return;
        }
  
        vec4 color = texture2D(u_s2Texture, v_f2TexCoord * u_scale + (1.0 - u_scale) * 0.5);
        if (color.a == 0.0) {
          discard;
        } else {
          color.rgb = uColor.rgb;
          color.a *= uColor.a;
          gl_FragColor = color;
        }
      }
    ` as unknown as string;
  }

  override getUniformLocations(gl: WebGLRenderingContext, program: WebGLProgram) {
    return {
      scale: gl.getUniformLocation(program, 'u_scale'),
      uColor: gl.getUniformLocation(program, 'uColor'),
    };
  }

  override sendUniformData(gl: WebGLRenderingContext, uniformLocations: { [name: string]: WebGLUniformLocation }) {
    gl.uniform4fv(uniformLocations.uColor, this.getColor());
    gl.uniform1f(uniformLocations.scale, this.scale);
  }

  getColor() {
    const shadowColor = this.shadowColor as string;
    const source = new FabricColor(shadowColor).getSource();
    source[0] /= 255;
    source[1] /= 255;
    source[2] /= 255;
    source[3] ??= 1;

    return source;
  }

  override applyToWebGL(p: TWebGLPipelineState) {
    const gl = p.context;
    gl.clearColor(0, 0, 0, 0);
    // eslint-disable-next-line no-bitwise
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    return super.applyToWebGL(p);
  }
}

export default ShadowFilter;
