import { util as fabricNativeUtils, Point as FabricPoint } from 'fabric';
import React from 'react';

import { LineEdge } from 'editor/src/store/design/types';

import CustomFabricObject from 'editor/src/fabric/CustomFabricObject';

import { POINT_0_0 } from 'editor/src/component/EditorArea/useCanvasRotation';

export const MIN_STROKE_WIDTH = 1;
export const MAX_STROKE_WIDTH = 100;

const getLineWidth = (x1: number, x2: number, y1: number, y2: number) => Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);

export const getLineAngle = (x1: number, x2: number, y1: number, y2: number) =>
  (x1 < x2
    ? fabricNativeUtils.radiansToDegrees(Math.atan((y1 - y2) / (x1 - x2))) || 0
    : fabricNativeUtils.radiansToDegrees(Math.atan((y1 - y2) / (x1 - x2))) + 180) || 0;

export const getLineCapOffset = (rounded: boolean, strokeWidth: number, angle: number) =>
  rounded
    ? fabricNativeUtils.rotatePoint(
        new FabricPoint(strokeWidth / 2, 0),
        POINT_0_0,
        fabricNativeUtils.degreesToRadians(angle),
      )
    : POINT_0_0;

export const getLineEdgeWidth = (strokeWidth: number) => strokeWidth * 3;

export const getLineEdgeHeight = (strokeWidth: number) => strokeWidth * 3;

export const getEdgeOffset = (edge: LineEdge, strokeWidth: number, angle: number) =>
  edge !== 'flat'
    ? fabricNativeUtils.rotatePoint(
        new FabricPoint(strokeWidth, 0),
        POINT_0_0,
        fabricNativeUtils.degreesToRadians(angle),
      )
    : POINT_0_0;

export const getArrowBoundingRectFromRef = (
  edgeRef: React.RefObject<CustomFabricObject>,
  strokeWidth: number,
  angle: number,
) => {
  const offset = fabricNativeUtils.rotatePoint(
    new FabricPoint(getLineEdgeHeight(strokeWidth), 0),
    POINT_0_0,
    fabricNativeUtils.degreesToRadians(angle),
  );
  return {
    left: (edgeRef.current?.left || 0) + offset.y,
    top: (edgeRef.current?.top || 0) - offset.x,
    width: (edgeRef.current?.width || 0) * 2,
    height: (edgeRef.current?.height || 0) * 2,
    angle: edgeRef.current?.angle || 0,
  };
};

export const getArrowBoundingRect = (strokeWidth: number, angle: number, left: number, top: number) => {
  const height = getLineEdgeHeight(strokeWidth);
  const width = getLineEdgeWidth(strokeWidth);
  const offset = fabricNativeUtils.rotatePoint(
    new FabricPoint(height, 0),
    POINT_0_0,
    fabricNativeUtils.degreesToRadians(angle),
  );
  return {
    left: left + offset.y,
    top: top - offset.x,
    width: width * 2,
    height: height * 2,
    angle,
  };
};

export const getLineEdgePath = (strokeWidth: number, left: number, top: number) => {
  const width = getLineEdgeWidth(strokeWidth);
  return `M ${left + width}, ${top + strokeWidth * 2}, ${left}, ${top}, ${left + width}, ${top - strokeWidth * 2}`;
};

export const getValidatedStrokeWidth = (strokeWidth: number) => {
  if (strokeWidth < MIN_STROKE_WIDTH) {
    return MIN_STROKE_WIDTH;
  }
  if (strokeWidth > MAX_STROKE_WIDTH) {
    return MAX_STROKE_WIDTH;
  }
  return strokeWidth;
};

export default getLineWidth;
