import { filters as fabricFilters } from 'fabric';

import { Options } from './types';

import type { T2DPipelineState, TMatColorMatrix, TWebGLPipelineState } from 'fabric/src/filters/typedefs';

class TrueBlackAndWhite extends fabricFilters.ColorMatrix {
  static override type = 'TrueBlackAndWhite';

  declare value: number;

  calculateMatrix() {
    const coloMatrix = [1.5, 1.5, 1.5, 0, -1, 1.5, 1.5, 1.5, 0, -1, 1.5, 1.5, 1.5, 0, -1, 0, 0, 0, 1, 0];

    const baseMatrix = [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0];

    const nVal = this.value / 100;

    this.matrix = coloMatrix.map((element, i) => element * nVal + baseMatrix[i] * (1 - nVal)) as TMatColorMatrix;
  }

  override isNeutralState(options: Options) {
    this.calculateMatrix();
    return fabricFilters.BaseFilter.prototype.isNeutralState.call(this, options);
  }

  override applyTo(options: TWebGLPipelineState | T2DPipelineState) {
    this.calculateMatrix();
    fabricFilters.BaseFilter.prototype.applyTo.call(this, options);
  }

  override toObject() {
    return {
      ...super.toObject(),
      value: this.value,
    };
  }
}

export default TrueBlackAndWhite;
