import { FabricObject, Group as FabricGroup } from 'fabric';

import CustomFabricPath from 'editor/src/fabric/CustomFabricPath';

export function setClipPath(element: FabricObject | undefined | null, clipPath: FabricObject | undefined) {
  if (!element) {
    return;
  }
  removeCacheCanvas(element.clipPath as CustomFabricPath);
  element.set('clipPath', clipPath);
}

export function disposeElement(element: FabricObject) {
  removeCacheCanvas(element.clipPath as CustomFabricPath);
  removeCacheCanvas(element);
  (element as any).dispose?.();
}

function clearAndRemoveCache(element: Partial<FabricObject> | undefined) {
  if (element && (element as any)._cacheCanvas) {
    (element as any)._cacheCanvas.width = 0;
    (element as any)._cacheCanvas.height = 0;
  }
  (element as any)?._removeCacheCanvas();
}

export function removeCacheCanvas(element: FabricObject | undefined) {
  if (element instanceof FabricGroup) {
    element._objects.forEach(clearAndRemoveCache);
  }
  clearAndRemoveCache(element);
}
