import { StaticCanvas, FabricImage } from 'fabric';
import React, { useImperativeHandle, useLayoutEffect, useMemo } from 'react';

import CustomFabricImage from 'editor/src/fabric/CustomFabricImage';
import useFabricCanvas from 'editor/src/util/useFabricCanvas';

import useEvent, { EventHandler } from './useEvent';
import useObjectProps from './useObjectProps';
import useObjectUpdate from './useObjectUpdate';

interface Props extends Partial<FabricImage> {
  sourceCanvas: StaticCanvas | HTMLCanvasElement;
  uuid?: number;
  zIndex?: number;
  onMouseDownBefore?: EventHandler;
  onMouseMove?: EventHandler;
  onMouseUp?: EventHandler;
  onMouseOver?: EventHandler;
  onMouseOut?: EventHandler;
  onModified?: EventHandler;
}

function FabricImageCanvasComponent(props: Props, ref: React.Ref<CustomFabricImage>) {
  const fabricCanvas = useFabricCanvas();
  const {
    sourceCanvas,
    filters,
    onMouseDownBefore,
    onMouseMove,
    onMouseUp,
    onMouseOver,
    onMouseOut,
    onModified,
    ...imageProps
  } = props;
  const srcElement = sourceCanvas instanceof HTMLCanvasElement ? sourceCanvas : sourceCanvas.getElement();
  const element = useMemo(() => new CustomFabricImage(srcElement as any), [srcElement]);
  useObjectProps(element, imageProps);

  useImperativeHandle(ref, () => element, [element]);

  useEvent(element, 'mouseover', onMouseOver);
  useEvent(element, 'mouseout', onMouseOut);
  useEvent(element, 'mousedown:before', onMouseDownBefore);
  useEvent(element, 'mousemove', onMouseMove);
  useEvent(element, 'mouseup', onMouseUp);
  useEvent(element, 'modified', onModified);

  useLayoutEffect(() => {
    if (!filters || sourceCanvas instanceof HTMLCanvasElement) {
      return undefined;
    }

    function onRender() {
      element.setElement((sourceCanvas as any).getElement());
      element.applyFilters();
    }

    sourceCanvas.on('after:render', onRender);
    return () => {
      sourceCanvas.off('after:render', onRender);
    };
  }, [filters, element, sourceCanvas]);

  useLayoutEffect(() => {
    if (filters) {
      element.filters = filters;
      element.applyFilters();
    }
  }, [filters, element]);

  useObjectUpdate(fabricCanvas, element);

  return null;
}

export default React.memo(React.forwardRef(FabricImageCanvasComponent));
