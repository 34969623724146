import { FabricObject } from 'fabric';
import { useLayoutEffect } from 'react';

export type EventHandler = (arg?: any) => void;

function useEvent(element: FabricObject, event: string, handler: EventHandler | undefined) {
  useLayoutEffect(() => {
    if (!handler) {
      return undefined;
    }

    element.on(event as any, handler as any);
    return () => {
      element.off(event as any, handler as any);
    };
  }, [handler, element]);
}

export default useEvent;
