import { Point as FabricPoint } from 'fabric';

import { Dimensions, SpreadGroundImage } from 'editor/src/store/design/types';

export function getSpreadGroundOffsets(image: SpreadGroundImage, contentDimensions: Dimensions) {
  const offsetLeft = (Math.abs(image.left) * contentDimensions.width) / image.width;
  const offsetTop = (Math.abs(image.top) * contentDimensions.height) / image.height;
  return { offsetTop, offsetLeft };
}

export function getSpreadImageZoom(imageDim: Dimensions, canvasDimensions: Dimensions) {
  return imageDim.width > imageDim.height
    ? canvasDimensions.width / imageDim.width
    : canvasDimensions.height / imageDim.height;
}

function getMinZoom(
  background: Dimensions | undefined,
  foreground: Dimensions | undefined,
  canvasDimensions: Dimensions,
  defaultZoom = 1,
) {
  const backgroundZoom = background ? getSpreadImageZoom(background, canvasDimensions) : defaultZoom;
  const foregroundZoom = foreground ? getSpreadImageZoom(foreground, canvasDimensions) : defaultZoom;
  return Math.min(backgroundZoom, foregroundZoom);
}

function getThumbnailZoomAndPan(
  defaultZoom: 'spread' | 'auto' | number,
  noCentering: boolean,
  canvasDim: Dimensions,
  contentDimensions: Dimensions,
  background: {
    image: SpreadGroundImage | undefined;
    dimensions: Dimensions | undefined;
  },
  foreground: {
    image: SpreadGroundImage | undefined;
    dimensions: Dimensions | undefined;
  },
): { zoom: number; center: FabricPoint; maxWidth: number; maxHeight: number } {
  if (defaultZoom === 'spread' || defaultZoom !== 'auto') {
    const zoom =
      defaultZoom === 'spread' ? getMinZoom(background.dimensions, foreground.dimensions, canvasDim) : defaultZoom;

    const diffX = (canvasDim.width - contentDimensions.width * zoom) / 2;
    const diffY = (canvasDim.height - contentDimensions.height * zoom) / 2;

    return {
      zoom,
      center: noCentering ? new FabricPoint(0, 0) : new FabricPoint(-diffX, -diffY),
      maxWidth: canvasDim.width,
      maxHeight: canvasDim.height,
    };
  }

  const zoom = getMinZoom(background.dimensions, foreground.dimensions, canvasDim);
  const backgroundOffset = (background.image && getSpreadGroundOffsets(background.image, contentDimensions)) || null;
  const foregroundOffset = (foreground.image && getSpreadGroundOffsets(foreground.image, contentDimensions)) || null;

  const maxWidth =
    Math.max(background.dimensions?.width || 0, foreground.dimensions?.width || 0, contentDimensions.width) * zoom;
  const offsetLeft = Math.max(backgroundOffset?.offsetLeft || 0, foregroundOffset?.offsetLeft || 0, 0) * zoom;
  const maxHeight =
    Math.max(background.dimensions?.height || 0, foreground.dimensions?.height || 0, contentDimensions.height) * zoom;
  const offsetTop = Math.max(backgroundOffset?.offsetTop || 0, foregroundOffset?.offsetTop || 0, 0) * zoom;

  return {
    zoom,
    center: new FabricPoint(
      -offsetLeft + (maxWidth - canvasDim.width) / 2,
      -offsetTop + (maxHeight - canvasDim.height) / 2,
    ),
    maxWidth,
    maxHeight,
  };
}

export default getThumbnailZoomAndPan;
