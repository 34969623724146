import { captureException } from '@sentry/react';
import { StaticCanvas } from 'fabric';

import { Coords, SpreadGroundImage } from 'editor/src/store/design/types';

import CustomFabricImage from 'editor/src/fabric/CustomFabricImage';
import loadImage from 'editor/src/util/loadImage';

const addSpreadImage = async (
  fabricCanvas: StaticCanvas,
  image: SpreadGroundImage | undefined,
  zIndex: number,
  offset: Coords,
  mm2px: (size: number) => number,
  previewWidth: number,
  previewHeight: number,
  useThumbnails: boolean,
) => {
  if (!image) {
    return undefined;
  }

  const imageUrl = useThumbnails ? image.thumbnailUrl : image.url;
  let img;
  try {
    img = await loadImage(imageUrl, 'anonymous', {
      executor: 'addSpreadImage',
    });
  } catch (e) {
    return undefined;
  }

  try {
    const imageScaleX = mm2px(previewWidth) / image.width;
    const imageScaleY = mm2px(previewHeight) / image.height;

    const fabricImg = new CustomFabricImage(img, {
      getCrossOrigin: () => 'anonymous',
      objectCaching: false,
      angle: 0,
      scaleX: imageScaleX * (image.imgWidth / img.naturalWidth),
      scaleY: imageScaleY * (image.imgHeight / img.naturalHeight),
      left: offset.left - image.left * imageScaleX,
      top: offset.top - image.top * imageScaleY,
      zIndex,
    });
    fabricCanvas.add(fabricImg);
    return {
      width: fabricImg.getScaledWidth(),
      height: fabricImg.getScaledHeight(),
    };
  } catch (e) {
    captureException(new Error(e || 'addSpreadImage: Error adding spread image to fabric'), { extra: { imageUrl } });
    return undefined;
  }
};

export default addSpreadImage;
