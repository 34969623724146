import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import getMediaElement from 'editor/src/store/design/selector/getMediaElement';
import { getStructureIndexByElementUuid } from 'editor/src/store/design/selector/getStructureIndexByElementUuid';
import { ElementAddress } from 'editor/src/store/design/types';
import canImageBeEmptied from 'editor/src/store/design/util/canImageBeEmptied';
import isLockedImageElementDeletionAllowed from 'editor/src/store/editor/selector/isLockedImageElementDeletionAllowed';
import { setSelectedElementUuidsAction } from 'editor/src/store/editor/slice';
import saveUndoRedoStateOperation from 'editor/src/store/editorModules/undoRedo/operation/saveUndoRedoStateOperation';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import getDeleteFrameWithImage from 'editor/src/store/hostSettings/selector/getDeleteFrameWithImage';
import { RootState } from 'editor/src/store/index';

import { DIMENSIONS } from 'editor/src/util/imagePlaceholder';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import applyImageToElementOperation from './applyImageToElementOperation';
import removeMediaElementOperation from './removeMediaElementOperation';

const clearImagesOrRemoveElementsOperation =
  (addresses: ElementAddress[]) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const deleteFrameWithImage = getDeleteFrameWithImage(state);
    const canDeleteLockedImageElement = isLockedImageElementDeletionAllowed(state);

    const elements = addresses.map((address) => getMediaElement(state, address));

    batch(() => {
      dispatch(saveUndoRedoStateOperation('Elements remove'));
      const uuidsToKeepSelected: number[] = [];
      elements.forEach((element) => {
        if (!element) {
          return;
        }

        if (element.type === 'image' && canImageBeEmptied(element) && !deleteFrameWithImage) {
          dispatch(applyImageToElementOperation(element, '', DIMENSIONS));
          uuidsToKeepSelected.push(element.uuid);
        } else if (!element.locked || canDeleteLockedImageElement) {
          // index will have changed when removing more than one element in the same spread
          const address = getStructureIndexByElementUuid(getState().design.designData, element.uuid);
          if (address) {
            dispatch(removeMediaElementOperation(address, false));
          }
          if (element.type === 'image' && element.imageId) {
            sendPostMessage('log.removeImageFrame', undefined);
          }
        }
      });

      dispatch(setSelectedElementUuidsAction(uuidsToKeepSelected));
    });
  };

export default clearImagesOrRemoveElementsOperation;
