import { FabricObject } from 'fabric';
import { useLayoutEffect } from 'react';

import { EventHandler } from './useEvent';

function useEvents(element: FabricObject, events: { [eventType: string]: EventHandler } | undefined) {
  useLayoutEffect(() => {
    if (!events) {
      return undefined;
    }

    const eventTypes = Object.keys(events);
    eventTypes.forEach((eventType) => element.on(eventType as any, events[eventType] as any));
    return () => eventTypes.forEach((eventType) => element.off(eventType as any, events[eventType] as any));
  }, [element, events]);
}

export default useEvents;
