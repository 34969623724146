import { filters as fabricFilters } from 'fabric';
import { T2DPipelineState, TMatColorMatrix } from 'fabric/src/filters/typedefs';

import { Options } from './types';

class Temperature extends fabricFilters.ColorMatrix {
  static override type = 'Temperature';

  declare shift: number;

  calculateMatrix() {
    this.matrix = [
      1 + this.shift / 500,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1 - this.shift / 500,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
    ] as TMatColorMatrix;
  }

  override isNeutralState(options: Options) {
    this.calculateMatrix();
    return fabricFilters.BaseFilter.prototype.isNeutralState.call(this, options);
  }

  override applyTo(options: T2DPipelineState) {
    this.calculateMatrix();
    fabricFilters.BaseFilter.prototype.applyTo.call(this, options);
  }

  override toObject() {
    return {
      ...super.toObject(),
      shift: this.shift,
    };
  }
}

export default Temperature;
