import { PayloadAction } from '@reduxjs/toolkit';

import { HostSettingsState } from 'editor/src/store/hostSettings/types';

const setHelpCenterArticlesSearchStringReducer = (state: HostSettingsState, action: PayloadAction<string>) => {
  if (!state.enableHelpCenter) {
    state.enableHelpCenter = {};
  }
  state.enableHelpCenter.articlesSearchString = action.payload;
};

export default setHelpCenterArticlesSearchStringReducer;
