import { setSpreadForegroundsAction } from 'editor/src/store/design/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';

import { RootState } from 'editor/src/store';

import { loadSpreadImages, ProductSpreadGrounds, SpreadGroundImageWODimensions } from './setSpreadBackgroundsOperation';

const setSpreadForegroundsOperation =
  (data: SpreadGroundImageWODimensions[] | ProductSpreadGrounds) =>
  async (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    // TODO remove backward compatibility block after the dashboard FE is deployed
    if (Array.isArray(data)) {
      const spreadGroundImages = await loadSpreadImages(data);
      dispatch(setSpreadForegroundsAction(spreadGroundImages));
    } else if (
      data.productUid === state.variants.selectedProductUid ||
      !data.productUid ||
      !state.variants.selectedProductUid
    ) {
      const spreadGroundImages = await loadSpreadImages(data.spreads);
      dispatch(setSpreadForegroundsAction(spreadGroundImages));
    }
  };

export default setSpreadForegroundsOperation;
