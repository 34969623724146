import { Point as FabricPoint, util as fabricNativeUtils } from 'fabric';
import { useMemo } from 'react';

import { CanvasRotation } from './types';

export const POINT_0_0 = new FabricPoint(0, 0);

export const NO_CANVAS_ROTATION: CanvasRotation = {
  angleDeg: 0,
  angleRad: 0,
  canvasCenter: POINT_0_0,
};

function useCanvasRotation(canvasRotation: CanvasRotation, x: number, y: number) {
  return useMemo(
    () => fabricNativeUtils.rotatePoint(new FabricPoint(x, y), canvasRotation.canvasCenter, canvasRotation.angleRad),
    [canvasRotation, x, y],
  );
}

export default useCanvasRotation;
