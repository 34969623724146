import { FabricImage, FabricObject, controlsUtils } from 'fabric';
import { ImageSource } from 'fabric/src/shapes/Image';

import { ObjectRect } from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Image/types';

import CustomControl from './FabricPathText/FabricControl';

export interface IFabricImageOptions extends Partial<FabricImage> {
  zIndex?: number;
  isLoaded?: boolean;
  frameRect?: ObjectRect;
  uuid?: number;
}

export function isFabricImage(object: FabricObject): object is CustomFabricImage {
  return object.type === 'gelato-image';
}

const commonControlOptions = {
  cursorStyleHandler: controlsUtils.scaleSkewCursorStyleHandler,
  getActionName: controlsUtils.scaleOrSkewActionName,
};

// the single way to overwrite controls in fabric 6 https://github.com/fabricjs/fabric.js/issues/9317#issuecomment-1715168983
FabricObject.ownDefaults.controls = {
  ...controlsUtils.createObjectDefaultControls(),
  ...FabricObject.ownDefaults.controls,
  ml: new CustomControl({
    x: -0.5,
    y: 0,
    orientation: 'vertical',
    actionHandler: controlsUtils.scalingXOrSkewingY,
    ...commonControlOptions,
  }),

  mr: new CustomControl({
    x: 0.5,
    y: 0,
    orientation: 'vertical',
    actionHandler: controlsUtils.scalingXOrSkewingY,
    ...commonControlOptions,
  }),

  mt: new CustomControl({
    x: 0,
    y: -0.5,
    orientation: 'horizontal',
    actionHandler: controlsUtils.scalingYOrSkewingX,
    ...commonControlOptions,
  }),

  mb: new CustomControl({
    x: 0,
    y: 0.5,
    orientation: 'horizontal',
    actionHandler: controlsUtils.scalingYOrSkewingX,
    ...commonControlOptions,
  }),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
class CustomFabricImage extends FabricImage<IFabricImageOptions> {
  static override type = 'gelato-image';

  declare groupSelectionDisabled: boolean;

  uuid: number | undefined;
  isLoaded: boolean = false;
  frameRect?: ObjectRect = undefined;

  constructor(element: ImageSource | string, options?: IFabricImageOptions) {
    super(element as any, options);
  }

  override _createCacheCanvas() {
    super._createCacheCanvas();
    if (this._cacheCanvas) {
      this._cacheCanvas.id = `fabric-cache-${this.type}-${this.uuid ?? ''}`;
    }
  }
}

export default CustomFabricImage;
