import memoize from 'lodash/memoize';

import { RootState } from 'editor/src/store/index';

import getMediaElementByUuid from './getMediaElementByUuid';

const getMediaElementsByUuids = memoize(
  (state: RootState, uuids: number[]) =>
    uuids.map((uuid: number) => getMediaElementByUuid(state, uuid)).filter((element) => element !== undefined),
  (state: RootState, uuids: number[]) =>
    JSON.stringify(
      uuids.map((uuid: number) => getMediaElementByUuid(state, uuid)).filter((element) => element !== undefined),
    ),
);

export default getMediaElementsByUuids;
