import React from 'react';

import { Coords, SpreadGroundImage } from 'editor/src/store/design/types';

import useFabricUtils from 'editor/src/util/useFabricUtils';

import FabricImageComponent from 'editor/src/component/EditorArea/fabricComponents/FabricImageComponent';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';
import useCanvasRotation from 'editor/src/component/EditorArea/useCanvasRotation';

interface Props {
  imageData: SpreadGroundImage;
  spreadHeight: number;
  spreadWidth: number;
  spreadCoords: Coords;
  zIndex: number;
  canvasRotation: CanvasRotation;
}

function SpreadGroundImageElement({
  spreadCoords,
  imageData,
  spreadWidth,
  spreadHeight,
  zIndex,
  canvasRotation,
}: Props) {
  const { mm2px } = useFabricUtils();
  const imageScaleX = mm2px(spreadWidth) / imageData.width;
  const imageScaleY = mm2px(spreadHeight) / imageData.height;

  const rotatedPoint = useCanvasRotation(
    canvasRotation,
    spreadCoords.left - imageData.left * imageScaleX,
    spreadCoords.top - imageData.top * imageScaleY,
  );

  return (
    <FabricImageComponent
      source={imageData.url}
      crossOrigin="anonymous"
      scaleX={imageScaleX}
      scaleY={imageScaleY}
      left={rotatedPoint.x}
      top={rotatedPoint.y}
      zIndex={zIndex}
      evented={false}
      selectable={false}
      angle={canvasRotation.angleDeg}
    />
  );
}

export default React.memo(SpreadGroundImageElement);
