import { Rect as FabricRect } from 'fabric';
import { RectProps } from 'fabric/src/shapes/Rect';

export interface ICustomFabricRect extends Partial<RectProps> {
  zIndex: number;
}

class CustomFabricRect extends FabricRect<ICustomFabricRect> {
  zIndex: number;

  constructor(options?: ICustomFabricRect) {
    super(options);
    this.zIndex = options?.zIndex ?? 0; // Ensure zIndex is always set
  }
}

export default CustomFabricRect;
