import { Polygon as FabricPolygon } from 'fabric';

interface iFabricPolygon extends Partial<FabricPolygon> {
  zIndex: number;
}

class CustomFabricPolygon extends FabricPolygon implements iFabricPolygon {
  declare zIndex: number;

  override _createCacheCanvas() {
    super._createCacheCanvas();
    if (this._cacheCanvas) {
      this._cacheCanvas.id = 'fabric-cache-polygon';
    }
  }
}

export default CustomFabricPolygon;
