import { StaticCanvas, FabricObject } from 'fabric';
import { Polygon } from 'polygon-clipping';

import { Coords, MediaRectangle } from 'editor/src/store/design/types';

import CustomFabricGroup, { IGroupOptions } from 'editor/src/fabric/CustomFabricGroup';
import CustomFabricRect, { ICustomFabricRect } from 'editor/src/fabric/CustomFabricRect';
import { getBrowserFriendlyColor } from 'editor/src/util/color/colorUtils';

import getClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getClipPath';
import getElementRect from 'editor/src/component/EditorArea/Spread/Page/MediaElement/getElementRect';
import getGroupInternalStrokeClipPath from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Rectangle/getGroupInternalStrokeClipPath';
import getGroupRectangleRect from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Rectangle/getGroupRectangleRect';
import rectangleElementDataToFabricProps from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Rectangle/rectangleElementDataToFabricProps';
import { CanvasRotation } from 'editor/src/component/EditorArea/types';

const addRectangle = (
  fabricCanvas: StaticCanvas,
  rectangleData: MediaRectangle,
  elementIndex: number,
  pageCoords: Coords,
  clipPathPolygons: Polygon[],
  contentClipPath: FabricObject | undefined,
  mm2px: (size: number) => number,
  canvasRotation: CanvasRotation,
) => {
  const frameRect = getElementRect(rectangleData, pageCoords, canvasRotation, mm2px);
  const fabricProps = rectangleElementDataToFabricProps(rectangleData, elementIndex, mm2px);
  fabricProps.fill = fabricProps.fill ? getBrowserFriendlyColor(fabricProps.fill) : undefined;
  fabricProps.stroke = fabricProps.stroke ? getBrowserFriendlyColor(fabricProps.stroke) : undefined;

  const clipPath = getClipPath(frameRect, clipPathPolygons, false);

  const groupOptions: IGroupOptions = {
    ...frameRect,
    objectCaching: false,
    strokeWidth: fabricProps.strokeWidth,
    zIndex: fabricProps.zIndex,
    clipPath,
  };

  const rectOptions: ICustomFabricRect = {
    ...fabricProps,
    ...getGroupRectangleRect(frameRect, fabricProps.strokeWidth * 2),
    clipPath: getGroupInternalStrokeClipPath(frameRect, fabricProps.rx || 0),
  };

  const groupObj = new CustomFabricGroup([new CustomFabricRect(rectOptions)], groupOptions);
  fabricCanvas.add(groupObj);
};

export default addRectangle;
