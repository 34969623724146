import { captureException } from '@sentry/react';
import { StaticCanvas } from 'fabric';
import { useRef, useCallback, useEffect } from 'react';

import disposeCanvas from 'editor/src/component/EditorArea/disposeCanvas';
import { disposeElement } from 'editor/src/component/EditorArea/fabricComponents/fabricUtils';

export type RequestRenderFn = <T>(cb: (canvas: StaticCanvas) => Promise<T>, id: string | number) => Promise<T>;
type RenderRequest = {
  cb: (canvas: StaticCanvas) => Promise<any>;
  id: string | number;
  res: (data: any) => void;
  promise: Promise<any>;
};

function useCanvasRendering(enable = true, preserveCanvas = false) {
  const canvasRef = useRef<StaticCanvas>();
  const used = useRef(false);
  const queue = useRef<RenderRequest[]>([]);

  useEffect(() => {
    if (!enable) {
      return undefined;
    }

    const domCanvas = document.createElement('canvas');
    domCanvas.id = 'canvas-rendering-queue';

    const fabricCanvas = new StaticCanvas(domCanvas, {
      renderOnAddRemove: false,
      imageSmoothingEnabled: false,
      backgroundColor: 'rgb(255, 255, 255)',
      width: 1,
      height: 1,
    });
    canvasRef.current = fabricCanvas;
    runQueue();
    return () => {
      if (!used.current) {
        disposeCanvas(fabricCanvas);
        canvasRef.current = undefined;
      }
    };
  }, [enable]);

  const runQueue = useCallback(() => {
    if (used.current || !canvasRef.current) {
      return;
    }

    const item = queue.current.shift();
    if (item) {
      used.current = true;
      const fabricCanvas = canvasRef.current;
      item
        .cb(fabricCanvas)
        .then((res) => {
          const objects = fabricCanvas.getObjects();
          objects.forEach(disposeElement);
          fabricCanvas.remove(...objects);

          // disabled or changed during the async process
          if (fabricCanvas !== canvasRef.current) {
            disposeCanvas(fabricCanvas);
          } else if (queue.current.length === 0 && !preserveCanvas) {
            fabricCanvas.setDimensions({ width: 1, height: 1 });
          }

          used.current = false;
          runQueue();
          item.res(res);
        })
        .catch((e) => {
          captureException(e || 'error in the rendering queue');
          used.current = false;
          runQueue();
        });
    }
  }, []);

  const requestRender = useCallback(
    (cb, id) => {
      let request = queue.current.find((q) => q.id === id);
      if (request) {
        request.cb = cb;
      } else {
        let res: any;
        const promise = new Promise((resolve) => {
          res = resolve;
        });
        request = {
          cb,
          id,
          promise,
          res,
        };
        queue.current.push(request);
      }
      runQueue();
      return request.promise;
    },
    [runQueue],
  );

  return { requestRender };
}

export default useCanvasRendering;
